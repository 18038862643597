import {Component, OnInit, ViewChild, ViewEncapsulation} from '@angular/core';
import BigNumber from 'bignumber.js';
import {from, Observable, of} from 'rxjs';
import * as anchor from '@project-serum/anchor';
import {catchError, switchMap} from 'rxjs/operators';
import {LockDTO} from 'src/app/dto/lock.dto';
import {ApiService} from 'src/app/services/api.service';
import {AuthService} from 'src/app/services/auth.service';
import {AutoDeployService} from 'src/app/services/autodeploy.service';
import {ClaimService} from 'src/app/services/claim.service';
import {EVM_TOKEN_PERCENTAGE_DECIMAL, Web3Service} from 'src/app/services/web3.service';
import {WalletConnectorService} from 'src/app/services/wallet-connector.service';
import {environment} from 'src/environments/environment';
import {Router} from "@angular/router";
import {SOLANA_TOKEN_PERCENTAGE_DECIMAL, SolanaWeb3ServiceV2} from 'src/app/services/solanav2.service';
import {Blockchain} from 'src/app/enums/blockchain_enum';
import {Moment} from "moment";
import { TON_TOKEN_PERCENTAGE_DECIMAL, TonService } from 'src/app/services/ton.service';

declare let window: any;

@Component({
  selector: 'app-create-claiming',
  templateUrl: './create-claiming.component.html',
  styleUrls: ['./create-claiming.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class CreateClaimingComponent implements OnInit {
  public dateTimePicker: Moment;
  public startDateDatePicker: Moment;
  public endDateDatePicker: Moment;
  public step: number = 1;
  public maxSteps: number = 6;

  public pageSize = 10;
  public page = 1;
  public maxPage = 0;


  public network: any;
  public project: any;
  public projectId: number;
  public projectName: string;


  public tokenAddress: string;
  public tokenInfo: any;
  public projectUrl: string;
  public fileName: string;
  public claimingWallets: Array<any>;
  public lockBalance: any;
  public depositLock: any;
  public vestingType: number = 1;
  public startDate: number;
  public unlocksNumber: number;
  public endDate: number;
  public refundDate: number;
  public unlocks: Array<any> = [{ startDate: null, percentage: null }]
  public unlocksCopy: Array<any> = [{ startDate: null, percentage: null }]

  public availableProject = this.api.getPlatforms();
  public tokenLoading: boolean = false;
  public transactionProcessing: boolean = false;
  public linearPercent: number;
  public isProd = environment.production;

  public get isConnected(): boolean {
    return this.web3.isConnected && this.auth.isAuthorized();
  }

  convertStarDateToTimestamp(dateString: Moment) {
    this.startDate = dateString.valueOf();
  }

  convertEndDateToTimestamp(dateString: Moment) {
    this.endDate = dateString.valueOf();
  }

  convertUnlockStartDateToTimestamp(dateString: Moment, id: number) {
    const timestampInMillis = dateString.valueOf();
    this.unlocks[id].startDate = Math.floor(timestampInMillis / 1000); // timestamp in seconds
    this.unlocksCopy[id].startDate = dateString;
  }

  convertUnlockEndDateToTimestamp(dateString: Moment, id: number) {
    const timestampInMillis = dateString.valueOf();
    this.unlocks[id].endDate = Math.floor(timestampInMillis / 1000); // timestamp in seconds
    this.unlocksCopy[id].endDate = dateString;
  }

  convertRefundDateToTimestamp(dateString: Moment) {
    this.refundDate = dateString.valueOf();
  }

  public get isSolanaConnected(): boolean {
    return this.solana.isConnected && this.auth.isAuthorized();
  }

  public get isTonConnected(): boolean {
    return this.ton.isConnected && this.auth.isAuthorized();
  }

  public currentAccount: Observable<string> = this.web3.currentAccount$;

  constructor(
    private readonly web3: Web3Service,
    private readonly solana: SolanaWeb3ServiceV2,
    private readonly ton: TonService,
    private readonly auth: AuthService,
    private readonly api: ApiService,
    private readonly claim: ClaimService,
    private deployService: AutoDeployService,
    private readonly walletConnector: WalletConnectorService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.walletConnector.initTon();
    this.walletConnector.initSolana();
  }

  public platformSelected($event: any): void {
    this.project = $event[0].data;
  }

  public calculateLockBalance(value: any): any {
    return this.depositLock = new BigNumber(value.replace(' ', '')).shiftedBy(18)
  }

  public async nextClick(): Promise<void> {
    if (this.step > this.maxSteps) return;
    if (this.step == 1) {
      //validate network
      if (!await this.validateNetwork()) return;
      // await this.solana.changeWallet('8BJDr7wR8EtAFnmzDnUqUzK36cr5CohhLqWzugUt2RXh', '7PoA4FFQSrBMkkW67odfNefH7VQHeJSfEs3ni4qTo3UH');
      // const availToken = await this.solana.getAmountAvailableToClaim('8BJDr7wR8EtAFnmzDnUqUzK36cr5CohhLqWzugUt2RXh', '7PoA4FFQSrBMkkW67odfNefH7VQHeJSfEs3ni4qTo3UH', '1500000000000', 9);
      // const availToken = await this.solana.getAmountAvailableToClaim('8BJDr7wR8EtAFnmzDnUqUzK36cr5CohhLqWzugUt2RXh', this.solana.currentAccountValue, '1500000000000', 9);
      // console.log('availToken >>>>', availToken);
    }
    if (this.step == 2) {
      this.tokenLoading = true;
      if (!await this.handleStepTwo()) return;
    }
    if (this.step == 4) {
      this.setMaxDepositValue();
    }
    if (this.step == 6) {
      this.transactionProcessing = true;
      this.complete();
      return;
    }
    this.step = this.step + 1;
  }

  private async complete(): Promise<void> {
    if (this.isTonConnected) {
      const createResult = await this.ton.createClaiming(this.tokenAddress, this.claimingWallets, this.unlocks, this.refundDate);
      if (!createResult) {
        this.transactionProcessing = false;
        return;
      }

      const lock = {
        blockchainId: Blockchain.Ton,
        version: 2,
        networkId: Number(this.ton.currentNetworkValue),
        platformId: this.project.id,
        contractAddress: createResult.distributorAddress,
        vaultAddress: createResult.vaultAddress,
        tokenAddress: this.tokenAddress,
        tokenSymbol: this.tokenInfo.tokenSymbol,
        tokenName: this.tokenInfo.tokenName,
        tokenDecimals: this.tokenInfo.tokenDecimal,
        projectName: this.projectName,
        projectIcon: this.projectUrl,
        amount: new BigNumber(this.lockBalance).toString(10),
        vestingTypeId: 1, // NonLinear
        startDate: this.startDate,
        endDate: this.endDate,
        refundDate: this.refundDate || null,
        unlocks: this.unlocks.map(i => {
          let intervalSec = i.isPeriod ? i.periodUnit : 0;
          return {
            startDate: i.startDate,
            endDate: i.endDate,
            percentage: i.percentage * TON_TOKEN_PERCENTAGE_DECIMAL,
            periodUnit: intervalSec,
            isUnlockedBeforeStart: i.isUnlockedBeforeStart || false
          }
        }),
        claimingWallets: this.claimingWallets,
        transactionHash: 'ton'
      } as LockDTO;
      console.log('lock data >>>', lock);

      const response = await this.api.createLock(lock).pipe(
        catchError((error: any) => {
          console.log('create lock api err >>>', error);
          return of(null);
        })
      ).toPromise();

      console.log("response", response);
      
      this.transactionProcessing = false;
      this.step = this.step + 1;
    }
    else if (this.isSolanaConnected) {

      let schedule = this.unlocks.map(i => {
        let times = i.isPeriod ? ((parseInt(i.endDate) - parseInt(i.startDate)) / parseInt(i.periodUnit) + 1) : 1;
        let intervalSec = i.isPeriod ? i.periodUnit : 1;
        return {
          startTs: new anchor.BN(i.startDate),
          tokenPercentage: new anchor.BN(i.percentage * SOLANA_TOKEN_PERCENTAGE_DECIMAL),
          times: new anchor.BN(times),
          intervalSec: new anchor.BN(intervalSec),
          airdropped: i.isUnlockedBeforeStart || false
        }
      });

      let { distributorAddress, vaultAddress } = await this.solana.createClaiming(this.tokenAddress, this.claimingWallets, schedule, this.refundDate);
      if (distributorAddress) {
        const lock = {
          blockchainId: 3,
          version: 2,
          networkId: 9999,
          platformId: this.project.id,
          contractAddress: distributorAddress,
          vaultAddress: vaultAddress,
          tokenAddress: this.tokenAddress,
          tokenSymbol: this.tokenInfo.tokenSymbol,
          tokenName: this.tokenInfo.tokenName,
          tokenDecimals: this.tokenInfo.tokenDecimal,
          projectName: this.projectName,
          projectIcon: this.projectUrl,
          amount: new BigNumber(this.lockBalance).toString(10),
          vestingTypeId: 1, // NonLinear
          startDate: this.startDate,
          endDate: this.endDate,
          refundDate: this.refundDate || null,
          unlocks: this.unlocks.map(i => {
            let intervalSec = i.isPeriod ? i.periodUnit : 0;
            return {
              startDate: i.startDate,
              endDate: i.endDate,
              percentage: i.percentage * SOLANA_TOKEN_PERCENTAGE_DECIMAL,
              periodUnit: intervalSec,
              isUnlockedBeforeStart: i.isUnlockedBeforeStart || false
            }
          }),
          claimingWallets: this.claimingWallets,
          transactionHash: 'solana'
        } as LockDTO;
        console.log('lock data >>>', lock);

        const response = await this.api.createLock(lock).pipe(
          catchError((error: any) => {
            console.log('create lock api err >>>', error);
            return of(null);
          })
        ).toPromise();

        console.log("response", response);
      }
      this.transactionProcessing = false;
      this.step = this.step + 1;

    } else if (this.isConnected) {
      this.deployService.deploy$.pipe(
        switchMap((contractAddress: string) => {

          console.log("claim address", contractAddress);
          const lock = {
            blockchainId: this.getBlockChainForEthRelatedNetworks(),
            version: 2,
            networkId: parseInt(this.web3.currentNetworkValue, 16), // network id
            platformId: this.project.id,
            tokenAddress: this.tokenAddress,
            tokenSymbol: this.tokenInfo.tokenSymbol,
            tokenName: this.tokenInfo.tokenName,
            tokenDecimals: this.tokenInfo.tokenDecimal,
            projectName: this.projectName,
            projectIcon: this.projectUrl,
            amount: new BigNumber(this.lockBalance).toString(10),
            vestingTypeId: 1, // NonLinear
            startDate: this.startDate,
            contractAddress: contractAddress,
            endDate: this.endDate,
            refundDate: this.refundDate || null, // TODO: set refund date
            unlocks: this.unlocks.map(i => ({ startDate: i.startDate, percentage: Math.round(i.percentage * EVM_TOKEN_PERCENTAGE_DECIMAL), endDate: i.endDate || 0, periodUnit: i.periodUnit || 0, isUnlockedBeforeStart: i.isUnlockedBeforeStart || false })),
            claimingWallets: this.claimingWallets
          } as LockDTO;

          return from(this.claim.createLock(lock))
        }),
        catchError(e => {
          console.log("error", e);
          return of(undefined);
        })
      ).subscribe(i => {
        this.transactionProcessing = false;
        if (!i) {
          return;
        } else {
          this.step = this.step + 1;
          //const amount = i.claimingWallets.reduce((previousValue:string, currentValue: string) => BigInt(previousValue) + BigInt(currentValue), BigInt(0));
          this.depositFunds(i.contractAddress, i.tokenAddress, this.depositLock);
        }
      })
    }
  }

  private getBlockChainForEthRelatedNetworks(): Blockchain {
    const networkId = parseInt(this.web3.currentNetworkValue);
    if(networkId == 1 || networkId == 4)
      return Blockchain.Ethereum;
    if(networkId == 56 || networkId == 97 || networkId == 321 || networkId == 322)
      return Blockchain.Binance;
    if(networkId == 137 || networkId == 8001)
      return Blockchain.Polygon;
    if(networkId == 43114 || networkId == 43113)
      return Blockchain.Avalanche;
    if(networkId == 42161 || networkId == 421614)
      return Blockchain.Arbitrum;
    if(networkId == 8453 || networkId == 84532)
      return Blockchain.Base;
    if(networkId == 81457 || networkId == 168587773)
      return Blockchain.Blast;
    return -1;
  }

  public async depositFunds(contractAddress: string, tokenAddress: string, amount: BigNumber): Promise<void> {
    this.web3.depositFundsToLock(contractAddress, tokenAddress, amount);
  }

  private async validateNetwork(): Promise<boolean> {

    if (this.isTonConnected) {
      return true;
    }

    if (this.ton.currentNetworkValue == this.network) {
      return true;
    }

    const networks = [
      97, // test bnb
      11155111, // test eth
      80002, // test polygon
      43113, // test avalanche
      56, // main bnb
      1, // main eth
      137, // main polygon
      43114, // main avalanche,
      322,
      321,
      42161,  // arbitrum one
      421614, // arbitrum sepolia
      8453,   // base
      84532,   // base sepolia,
      81457,   // blast
      168587773   // blast sepolia
    ];

    if (this.web3.currentNetworkValue == this.web3.web3Instance.utils.toHex(networks[this.network])) {
      return true;
    }
    try {
      await window.ethereum.request({
        method: 'wallet_switchEthereumChain',
        params: [{ chainId: this.web3.web3Instance.utils.toHex(networks[this.network]) }],
      });
      return true;
    } catch (switchError: any) {
      if (switchError.code === 4902) {
        alert('add this chain id');

        try {
          await window.ethereum.request({
            method: 'wallet_addEthereumChain',
            params: [environment.networkData[this.network]],
          });
          return true;
        } catch (addError) {
          console.error(addError);
          return false;
          //this.userSessionProvider.finishSession();
        }
      }
      return false;
    }

  }

  public backClick(): void {
    if (this.step == 1) return;
    this.step = this.step - 1;
  }

  public changeAccountType(): void {
  }

  public onWalletsFileSelected(event: any): void {
    this.fileName = event.srcElement.files[0].name;
    let reader: FileReader = new FileReader();
    reader.onload = (e) => {
      this.claimingWallets = this.parseCSV(reader.result as string);
      this.maxPage = Math.floor((this.claimingWallets.length + this.pageSize - 1) / this.pageSize);
    }
    reader.readAsText(event.target.files[0]);
  }

  public onUnlocksFileSelected(event: any): void {
    let reader: FileReader = new FileReader();
    reader.onload = (e) => {
      let data = this.parseUnlocksCSV(reader.result as string);
      this.unlocks = data;
      this.unlocksCopy = data;
    }
    reader.readAsText(event.target.files[0]);
  }

  public setMaxDepositValue(): void {
    const amount = this.claimingWallets.map(i => i.amount).reduce((previousValue: string, currentValue: string) => BigInt(previousValue) + BigInt(currentValue), BigInt(0));
    this.lockBalance = amount.toString().replace('n', '');
  }

  public onProjectIconSelected(event: any): void {
    if (event.target.files && event.target.files[0]) {
      var reader = new FileReader();

      reader.onload = (event: any) => {
        this.projectUrl = event.target.result;
      }

      reader.readAsDataURL(event.target.files[0]);
    }
  }

  public selectAll(): void {
    this.claimingWallets.forEach(i => i.selected = true);
  }
  public delete(): void {
    this.claimingWallets = this.claimingWallets.filter(i => !i.selected);
  }
  public unselect(): void {
    this.claimingWallets.filter(i => i.selected).forEach(i => i.selected = false);
  }

  public removeWallet(walletInfo: any): void {
    const index = this.claimingWallets.indexOf(walletInfo, 0);
    if (index > -1) {
      this.claimingWallets.splice(index, 1);
    }
  }

  public changeWallet(walletInfo: any): void {
  }

  public deleteFile(): void {
    this.fileName = null;
    this.claimingWallets = null;
  }

  public addUnlock(): void {
    this.unlocks.push({ startDate: null, percentage: null })
    this.unlocksCopy.push({ startDate: null, percentage: null })
  }

  public removeUnlock(idx: number): void {
    this.unlocks.splice(idx, 1);
    this.unlocksCopy.splice(idx, 1);
  }

  public reset() {
    window.location.reload();
  }

  private async handleStepTwo(): Promise<any> {

    if (!this.tokenAddress || !this.project) {
      this.tokenLoading = false;
      return;
    }
    else {
      if (this.isTonConnected) this.tokenInfo = await this.ton.getTokenInfo(this.tokenAddress);
      if (this.isSolanaConnected) this.tokenInfo = await this.solana.getTokenInfo(this.tokenAddress);
      if (this.isConnected) this.tokenInfo = await this.web3.getTokenInfo(this.tokenAddress);
      this.tokenLoading = false;
      if (!this.tokenInfo) {
        console.log("error token address info");
        return;
      } else {
        console.log('tokeninfo', this.tokenInfo);
        return {}
      }

    }
    //todo error message token address is required;
  }

  private parseCSV(readerResult: string): any {
    let allTextLines = readerResult.split(/\r|\n|\r/);
    let headers = allTextLines[0].split(',');
    const wallets = [];
    for (let i = 1; i < allTextLines.length; i++) {
      // split content based on comma
      let data = allTextLines[i].split(',');
      if (data.length === headers.length) {
        let tarr = [];
        for (let j = 0; j < headers.length; j++) {
          tarr.push(data[j]);
        }
        wallets.push({ address: tarr[0], amount: tarr[1].trim(), selected: false });
      }
    }
    return wallets;
  }

  private parseUnlocksCSV(readerResult: string): any {
    let allTextLines = readerResult.split(/\r|\n|\r/);
    let headers = allTextLines[0].split(',');
    const unlocks = [];
    for (let i = 1; i < allTextLines.length; i++) {
      // split content based on comma
      let data = allTextLines[i].split(',');
      if (data.length === headers.length) {
        let tarr = [data[2], data[3]];
        unlocks.push({ startDate: tarr[0].trim(), percentage: tarr[1].trim() });
      }
    }
    return unlocks;
  }

  goToMainPage() {
    this.router.navigate(['/']);
  }
}
