import { Address, beginCell, Builder, Cell, Dictionary, Slice, Transaction } from "@ton/core";
import { Claiming, loadUnlockPeriod, storeUnlockPeriod, UnlockPeriod } from "./Claiming";

const sleep = (ms: number) => new Promise((r) => setTimeout(r, ms));
const currentTime = () => Math.round(new Date().getTime() / 1000);

const shortenString = function (str: string): string {
    return str.length <= 8 ? str : `${str.slice(0, 4)}...${str.slice(-4)}`;
}

// const findEvent = function (result: SendMessageResult, eventName: string, eventFunction: any): any {
//     for (const external of result.externals) {
//         var slice = external.body.beginParse();
//         var event = eventFunction(slice)

//         if (event['$$type'] == eventName) {
//             return event;
//         }
//     }

//     return null;
// }

const getErrors = async function() {
    const customErrors: { [key: number]: { message: string } } = {
        42: { message: `Error parsing exotic cell` },
        43: { message: `Exotic cell is not merkle proof` },
        44: { message: `Merkle root mismatch` },
        45: { message: `Entry not found im merkle proof` },
    }

    var cl = await Claiming.fromInit();
    return { ...cl.abi.errors!!, ...customErrors };
}

const getErrorMessage = async function (errorCode: number): Promise<string> {
    const errors = await getErrors();
    return errors[errorCode]?.message ?? errorCode.toString();
}

const getErrorCodeByMessage = async function (message: string): Promise<number> {
    const errors = await getErrors();
    for (const err in errors) {
        if (errors[err].message == message) {
            return Number(err);
        }
    }

    return 0;
}

const getOpName = async function(op: number): Promise<string> {
    var cl = await Claiming.fromInit();
    return cl.abi.types.find(x => x.header == op)?.name || op.toString();
}

// const ensureAllTransactionsSuccess = async function (result: SendMessageResult) {
//     for (const tx of flattenTransactions(result)) {
//         if (tx.exitCode !== 0) {
//             const msg = await getErrorMessage(tx.exitCode!!);
//             const from = shortenString(tx.from!!.toString());
//             const to = shortenString(tx.to!!.toString());

//             throw `Transaction from ${from} to ${to} failed: ${msg}`;
//         }
//     }
// }

//-------------------------------------------------------------------------------------------

type ClaimEntry = {
    address: Address;
    amount: bigint;
};

const claimEntryValue = {
    serialize: (src: ClaimEntry, buidler: Builder) => {
        buidler.storeAddress(src.address).storeCoins(src.amount);
    },
    parse: (src: Slice) => {
        return {
            address: src.loadAddress(),
            amount: src.loadCoins(),
        };
    },
};

function generateEntriesDictionary(entries: ClaimEntry[]): Dictionary<bigint, ClaimEntry> {
    let dict: Dictionary<bigint, ClaimEntry> = Dictionary.empty(Dictionary.Keys.BigUint(256), claimEntryValue);

    for (let i = 0; i < entries.length; i++) {
        dict.set(BigInt(i), entries[i]);
    }

    return dict;
}

const getEntryIndex = function (entries: ClaimEntry[], address: Address): bigint {
    return BigInt(entries.findIndex(x => x.address.equals(address)));
}

const getMerkleRoot = function (entries: ClaimEntry[]): bigint {
    let dictionary: Dictionary<bigint, ClaimEntry> = generateEntriesDictionary(entries);

    let dictCell: Cell = beginCell().storeDictDirect(dictionary).endCell();
    let merkleRoot = BigInt('0x' + dictCell.hash().toString('hex'));

    return merkleRoot;
}

const getMerkleProof = function (entries: ClaimEntry[], index: bigint): Cell {
    let dictionary: Dictionary<bigint, ClaimEntry> = generateEntriesDictionary(entries);
    return dictionary.generateMerkleProof([index]);
}

const getUserData = function(entries: ClaimEntry[], address: Address): any {
    const index = getEntryIndex(entries, address);

    if (index === BigInt(-1)) {
        throw `Address ${address.toString()} not found in entries`;
    }

    const merkleProof = getMerkleProof(entries, index);

    return { index, merkleProof };
}

// ------------------------------------------------------------------------------------

const unlockEntryValue = {
    serialize: (src: UnlockPeriod, buidler: Builder) => {
        buidler.storeRef(beginCell().store(storeUnlockPeriod(src)));
    },
    parse: (src: Slice) => {
        return loadUnlockPeriod(src.loadRef().beginParse());
    },
};

function generateUnlocksDictionary(entries: UnlockPeriod[]): Dictionary<bigint, UnlockPeriod> {
    let dict: Dictionary<bigint, UnlockPeriod> = Dictionary.empty(Dictionary.Keys.BigUint(256), unlockEntryValue);

    for (let i = 0; i < entries.length; i++) {
        dict.set(BigInt(i), entries[i]);
    }

    return dict;
}

//--------------------------------------------------------------------------------------

function generateRefundedDictionary(addresses: Address[], value: boolean): Dictionary<Address, boolean> {
    let dict = Dictionary.empty(Dictionary.Keys.Address(), Dictionary.Values.Bool());

    for (let address of addresses) {
        dict.set(address, value);
    }

    return dict;
}

//---------------------------------------------------------------------------------------

function extractOp(body: Cell, bounced: boolean) {
    const s = body.beginParse();
    if (s.remainingBits >= 32) {

        if (bounced) {
            s.skip(32);
        }

        return s.preloadUint(32);
    }
    else {
        return undefined;
    }
}

function extractQueryId(body: Cell, bounced: boolean) {
    const s = body.beginParse();
    
    if (s.remainingBits >= 96) {
        s.skip(bounced ? 64 : 32);

        try {
            return s.preloadUintBig(64);
        }
        catch {}
    }
   
    return undefined;
}

function flattenTransaction(tx: Transaction) {
    return {
        lt: tx.lt,
        now: tx.now,
        outMessagesCount: tx.outMessagesCount,
        oldStatus: tx.oldStatus,
        endStatus: tx.endStatus,
        totalFees: tx.totalFees.coins,
        innerErrorMessage: '',
        ...(tx.inMessage ? {
            from: tx.inMessage.info.src instanceof Address ? tx.inMessage.info.src : undefined,
            to: tx.inMessage.info.dest,
            on: tx.inMessage.info.dest,
            value: tx.inMessage.info.type === 'internal' ? tx.inMessage.info.value.coins : undefined,
            body: tx.inMessage.body,
            inMessageBounced: tx.inMessage.info.type === 'internal' ? tx.inMessage.info.bounced : undefined,
            inMessageBounceable: tx.inMessage.info.type === 'internal' ? tx.inMessage.info.bounce : undefined,
            op: extractOp(tx.inMessage.body, tx.inMessage.info.type === 'internal' ? tx.inMessage.info.bounced : false),
            queryId: extractQueryId(tx.inMessage.body, tx.inMessage.info.type === 'internal' ? tx.inMessage.info.bounced : false),
            initData: tx.inMessage.init?.data ?? undefined,
            initCode: tx.inMessage.init?.code ?? undefined,
            deploy: tx.inMessage.init ? (tx.oldStatus !== 'active' && tx.endStatus === 'active') : false,
        } : {
            from: undefined,
            to: undefined,
            on: undefined,
            value: undefined,
            body: undefined,
            inMessageBounced: undefined,
            inMessageBounceable: undefined,
            op: undefined,
            queryId: undefined,
            initData: undefined,
            initCode: undefined,
            deploy: false,
        }),
        ...(tx.description.type === 'generic'
            || tx.description.type === 'tick-tock'
            || tx.description.type === 'split-prepare'
            || tx.description.type === 'merge-install'
            ? {
                aborted: tx.description.aborted,
                destroyed: tx.description.destroyed,
                exitCode: tx.description.computePhase.type === 'vm' ? tx.description.computePhase.exitCode : undefined,
                actionResultCode: tx.description.actionPhase?.resultCode,
                success: tx.description.computePhase.type === 'vm'
                    ? (tx.description.computePhase.success && tx.description.actionPhase?.success)
                    : false,
            } : {
            aborted: undefined,
            destroyed: undefined,
            exitCode: undefined,
            actionResultCode: undefined,
            success: undefined,
        }),
    };
}

export {
    sleep,
    currentTime,
    //findEvent,
    getErrorMessage,
    getErrorCodeByMessage,
    getOpName,
    //ensureAllTransactionsSuccess,

    ClaimEntry,
    getEntryIndex,
    getMerkleRoot,
    getMerkleProof,
    getUserData,

    generateUnlocksDictionary,
    generateRefundedDictionary,

    flattenTransaction
};