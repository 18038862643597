<section class="selector_project">
  <div class="project-wrapper">
    <h2>Welcome to BullClaimer</h2>
    <div class="h2_p">Claim tokens in the deals you've joined.</div>

    <div class="project">
      <div class="project-top">
        <div class="project-top-left">
          <input type="text" placeholder="Search Project/Value" (change)="changeSearch($event)">
          <img src="assets/images/Search.png" alt="">
        </div>
        <div class="project-top-right">
          <a [ngClass]="{'act': lockType == 0}" (click)="changeLockFilter(0)">All Locks</a>
          <a [ngClass]="{'act': lockType == 1}" (click)="changeLockFilter(1)"
            *ngIf="isConnected || isSolanaConnected || isTonConnected">My Locks</a>
          <!-- <a *ngIf="isConnected">Settings</a> -->
        </div>
      </div>

      <div class="blockchain blockchain_filter">
        <div class="blockchain_item">
          <input type="radio" name="blockchain" id="all" [checked]="projectTypeChangeTrigger$.value == 0">
          <label for="all" (click)="changeType(0)">
            <div class="blockchain_item_c">All</div>
          </label>
        </div>

        <div class="blockchain_item">
          <input type="radio" name="blockchain" id="bullperk" [checked]="projectTypeChangeTrigger$.value == 1">
          <label for="bullperk" (click)="changeType(1)">
            <div class="blockchain_item_c"><img src="assets/images/BullPerks2.png" alt="BullPerks">BullPerks
            </div>
          </label>
        </div>

        <div class="blockchain_item">
          <input type="radio" name="blockchain" id="gamespad" [checked]="projectTypeChangeTrigger$.value == 2">
          <label for="gamespad" (click)="changeType(2)">
            <div class="blockchain_item_c"><img src="assets/images/GamesPad.png" alt="GamesPad">GamesPad
            </div>
          </label>
        </div>
      </div>

      <div class="project-bottom project-bottom_ver2 project-loading" *ngIf="pageSlice; else loading">
        <div class="project-bottom-top" [ngClass]="{'proj-not-connected': !(isConnected || isSolanaConnected || isTonConnected)}">
          <div (click)="sort('projectName')" [ngClass]="{'header-asc-sort': (sortColumn == 'projectName' && !isDescSort), 'header-desc-sort': (sortColumn == 'projectName' && isDescSort)}">Project page</div>
          <div (click)="sort('blockchain')" [ngClass]="{'header-asc-sort': (sortColumn == 'blockchain' && !isDescSort), 'header-desc-sort': (sortColumn == 'blockchain' && isDescSort)}" >Blockchain</div>
          <div (click)="sort('platform|name')" [ngClass]="{'header-asc-sort': (sortColumn == 'platform|name' && !isDescSort), 'header-desc-sort': (sortColumn == 'platform|name' && isDescSort)}">Platform</div>
          <div (click)="sort('allocationReduced')" *ngIf="isConnected || isSolanaConnected || isTonConnected" [ngClass]="{'header-asc-sort': (sortColumn == 'allocationReduced' && !isDescSort), 'header-desc-sort': (sortColumn == 'allocationReduced' && isDescSort)}">Your allocation</div>
          <div (click)="sort('amountReduced')" *ngIf="!(isConnected || isSolanaConnected || isTonConnected)" [ngClass]="{'header-asc-sort': (sortColumn == 'amountReduced' && !isDescSort), 'header-desc-sort': (sortColumn == 'amountReduced' && isDescSort)}">Tokens Locked</div>
          <div (click)="sort('claimableReduced')" *ngIf="isConnected || isSolanaConnected || isTonConnected" [ngClass]="{'header-asc-sort': (sortColumn == 'claimableReduced' && !isDescSort), 'header-desc-sort': (sortColumn == 'claimableReduced' && isDescSort)}">Claimable now</div>
          <div (click)="sort('startDate')"
            *ngIf="!(isConnected || isSolanaConnected || isTonConnected) || isConnected || isSolanaConnected || isTonConnected" [ngClass]="{'header-asc-sort': (sortColumn == 'startDate' && !isDescSort), 'header-desc-sort': (sortColumn == 'startDate' && isDescSort)}">Next Release</div>
          <div *ngIf="isConnected || isSolanaConnected || isTonConnected">Claim</div>
          <div *ngIf="isConnected || isSolanaConnected || isTonConnected"></div>
        </div>

        <div class="project-bottom-b" [ngClass]="{'proj-not-connected': !(isConnected || isSolanaConnected || isTonConnected)}"
          *ngFor="let lock of pageSlice trackBy: lockIdTrackFunction">
          <div class="project-bottom-name" [routerLink]="['platform', lock.platform.name, lock.projectName, lock.id]"
            style="cursor: pointer;">
            <p>
              <img src="{{lock.projectIcon}}" width="24" height="24">
              {{lock.projectName}}
            </p>
          </div>

          <!-- Blockchain Network -->
          <div *ngIf="lock.networkId == 97 || lock.networkId == 56" class="mobileTitleTableBl">
            <div class="mobileTitleTable">Blockchain</div>
            <div><img src="assets/images/binance32.png" width="28" height="28" alt="">BNB Chain</div>
          </div>
          <div *ngIf="lock.networkId == 11155111 || lock.networkId == 1" class="mobileTitleTableBl">
            <div class="mobileTitleTable">Blockchain</div>
            <div><img src="assets/images/eth.png" width="28" height="28" alt="">ETH</div>
          </div>
          <div *ngIf="lock.networkId == 80002 || lock.networkId == 137" class="mobileTitleTableBl">
            <div class="mobileTitleTable">Blockchain</div>
            <div><img src="assets/images/Polygon.png" width="28" height="28" alt="">Polygon</div>
          </div>
          <div *ngIf="lock.networkId == 43113 || lock.networkId == 43114" class="mobileTitleTableBl">
            <div class="mobileTitleTable">Blockchain</div>
            <div><img src="assets/images/Avalanche.png" width="28" height="28" alt="">Avalanche</div>
          </div>
          <div *ngIf="lock.blockchain == 'solana'" class="mobileTitleTableBl">
            <div class="mobileTitleTable">Blockchain</div>
            <div><img src="assets/images/Solana.png" width="28" height="28" alt="">Solana</div>
          </div>
          <div *ngIf="lock.networkId == 321 || lock.networkId == 322" class="mobileTitleTableBl">
            <div class="mobileTitleTable">Blockchain</div>
            <div><img src="assets/images/kcc.png" width="28" height="28" alt="">kcc</div>
          </div>
          <div *ngIf="lock.networkId == 42161 || lock.networkId == 421614" class="mobileTitleTableBl">
            <div class="mobileTitleTable">Blockchain</div>
            <div><img src="assets/images/arbitrum.png" width="28" height="28" alt="">Arbitrum</div>
          </div>
          <div *ngIf="lock.networkId == 8453 || lock.networkId == 84532" class="mobileTitleTableBl">
            <div class="mobileTitleTable">Blockchain</div>
            <div><img src="assets/images/base.png" width="28" height="28" alt="">Base</div>
          </div>
          <div *ngIf="lock.networkId == 81457 || lock.networkId == 168587773" class="mobileTitleTableBl">
            <div class="mobileTitleTable">Blockchain</div>
            <div><img src="assets/images/blast.png" width="28" height="28" alt="">Blast</div>
          </div>
          <div *ngIf="lock.networkId == -3 || lock.networkId == -239" class="mobileTitleTableBl">
            <div class="mobileTitleTable">Blockchain</div>
            <div><img src="assets/images/ton.png" width="28" height="28" alt="">TON</div>
          </div>

          <!-- Platform -->
          <div class="mobileTitleTableBl">
            <div class="mobileTitleTable">Platform</div>
            <div><img src="{{lock.platform.icon}}" width="24" height="24" alt="">{{lock.platform.name}}</div>
          </div>

          <!-- Tokens Locked -->
          <div *ngIf="isConnected || isSolanaConnected || isTonConnected" class="mobileTitleTableBl">
            <div class="mobileTitleTable">Your allocation</div>
            <div>{{formatNumber(lock.allocationReduced)}}</div>
          </div>
          <div *ngIf="!(isConnected || isSolanaConnected || isTonConnected)" class="mobileTitleTableBl">
            <div class="mobileTitleTable">Tokens locked</div>
            <div>{{formatNumber(lock.amountReduced)}} </div>
          </div>

          <!-- Claimable now -->
          <div class="mobileTitleTableBl" *ngIf="isConnected || isSolanaConnected || isTonConnected">
            <div class="mobileTitleTable">Claimable now</div>
            <div>{{formatNumber(lock.claimableReduced)}}</div>
          </div>
          <!-- Timer -->

          <!-- isStopped for solana not requesting state from SC KNOWN ISSUE -->
          <!-- Go to claim -->

          <div *ngIf="!(isConnected || isSolanaConnected || isTonConnected)"
            [routerLink]="['platform', lock.platform.name, lock.projectName, lock.id]">
            <a *ngIf="lock.isStopped">Canceled</a>
            <a *ngIf="!lock.isStopped && showTimer(lock) && tabActive">
              <cd-timer [countdown]="true" [startTime]="lock.timeLeft"
                (onComplete)="handleCountDownEndsEvent($event, lock)"></cd-timer>
            </a>
            <a *ngIf="!showTimer(lock) && !lock.isStopped">Finished</a>
          </div>

          <div class="mobileTitleTableBl" *ngIf="!lock.isRefunded && (isConnected || isSolanaConnected || isTonConnected)">
            <div class="mobileTitleTable">Next unlock</div>
            <div *ngIf="!lock.isStopped && showTimer(lock) && tabActive">
              <cd-timer [countdown]="true" [startTime]="lock.timeLeft"
                (onComplete)="handleCountDownEndsEvent($event, lock)"></cd-timer>
            </div>
            <div *ngIf="(isConnected || isTonConnected) && lock.isStopped">Canceled</div>
            <!-- <div *ngIf="isSolanaConnected && lock.isStopped && showTimer">Canceled</div> -->
            <div *ngIf="(isConnected || isTonConnected) && !lock.isStopped && !showTimer(lock)">Finished</div>
            <div *ngIf="isSolanaConnected && lock.isStopped">Finished</div>
          </div>

          <div *ngIf="lock.isRefunded">
            Refunded
          </div>

          <div *ngIf="(isConnected || isSolanaConnected || isTonConnected) && !lock.isLoading">
            <a [routerLink]="['platform', lock.platform.name, lock.projectName, lock.id]">Go to Claim</a>

          </div>

          <div *ngIf="(isConnected || isSolanaConnected || isTonConnected) && !lock.isLoading">
            <div *ngIf="editAvailable()" [routerLink]="['edit-claiming', lock.id]" style="cursor: pointer;"
              class="edit-claim">
              <img src="assets/images/Edit.png" alt="">
            </div>
          </div>

          <div *ngIf="lock.isLoading" style="width: 40px;height: 40.99px;">
            <div class="icons_popap wait_animation" style="width: 100%;"><img src="assets/images/wait.png" width="28"
                height="28" alt=""></div>
          </div>

        </div>


        <!-- <div class="navigation_slider">
                <a class="prevSlide">&#10094;</a>
                <a class="nextSlide">&#10095;</a>
              </div> -->
      </div>

      <mat-paginator
        *ngIf="pageSlice"
        hidePageSize
        showFirstLastButtons
        [length]="locks.length"
        [pageSize]="20"
        (page)="onPageChange($event)"
        class="locks-paginator">
      </mat-paginator>
    </div>



    <ng-template #loading>
      <div class="icons_popap "><img class="wait_animation" src="assets/images/wait.png" alt=""></div>
      <div class="wait_info">Please, wait...</div>
    </ng-template>
  </div>
</section>
