import { Pipe, PipeTransform } from '@angular/core';
import { Observable } from 'rxjs';

@Pipe({
  name: 'chainIcon',
})
export class ChainIconPipe implements PipeTransform {
  transform(id: number): string | Observable<string> {
    switch (id) {
      case 1:
      case 11155111:
        return 'assets/images/eth.png';
      case 56:
      case 97:
        return 'assets/images/bnb.png';
      case 80002:
      case 137:
        return 'assets/images/Polygon.png';
      case 43113:
      case 43114:
        return 'assets/images/Avalanche.png';
      case 9999:
        return 'assets/images/Solana.png';
      case 321:
        return 'assets/images/kcc.png';
      case 322:
        return 'assets/images/kcc.png';
      case 42161:
      case 421614:
        return 'assets/images/arbitrum.png';
      case 8453:
      case 84532:
        return 'assets/images/base.png';
      case 81457:
      case 168587773:
        return 'assets/images/blast.png';
      case 10242:
      case 10243:
        return 'assets/images/arthera.png';
      case -3:
      case -239:
        return 'assets/images/ton.png';
      default:
        return 'assets/images/image 1.png';
    }
  }
}
