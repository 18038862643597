<section class="selector_project">
  <div class="container">

    <div class="project_claimer">
      <div class="project_claimer_top">
        <div class="project_claimer_img"><img src="{{lockInfo?.projectIcon}}" alt=""></div>
        <div class="project_claimer_info">
          <div class="t" data-da=".project_claimer_img,1199,1">{{lockInfo?.projectName}}</div>
          <p> <span class="token-address-wrapper" (click)="copyAddress()"><img
                src="../../../assets/images/copy-to-clipboard-icon.svg" alt="copy">{{lockInfo?.tokenAddress}}</span>
            <span (click)="addTokenToMetamask()"><img *ngIf="lockInfo?.blockchain != 'solana' && lockInfo?.blockchain != 'ton'"
                src="assets/images/image 1.png" alt="">
              <!--<img *ngIf="lockInfo?.blockchain == 'solana'" src="assets/images/Solana.png" alt="">-->
            </span>
          </p>
        </div>
        <div class="project_claimer_token_wrapper">
          <div class="project_claimer_token">
            <div *ngIf="lockInfo?.blockchain != 'solana' && lockInfo?.blockchain != 'ton'" class="project_claimer_token_item">
              <img src="{{lockInfo?.networkId | chainIcon}}" width="28" height="28" alt="">
              {{lockInfo?.networkId | chainName}}
            </div>
            <div *ngIf="lockInfo?.blockchain == 'solana'" class="project_claimer_token_item">
              <img src="assets/images/Solana.png" width="28" height="28" alt="">
              Solana
            </div>
            <div *ngIf="lockInfo?.blockchain == 'ton'" class="project_claimer_token_item">
              <img src="assets/images/ton.png" width="28" height="28" alt="">
              TON
            </div>

            <div class="project_claimer_token_item">
              <img src="{{lockInfo?.platform.icon}}" alt="">
              {{lockInfo?.platform.name}}
            </div>
          </div>
          <div class="">
            <div class="ml-auto deal-price" *ngIf="lockInfo?.projectPrice">
              <img src="../../../assets/images/Coins.svg">
              Deal Price: <span class="text-white">${{lockInfo?.projectPrice}}</span>
            </div>
            <div (click)="alertCantClaim()" class="project_claimer_info_cant_claim ml-auto">
              <img src="../../../assets/images/Help.svg">
              Can't claim?
            </div>
          </div>
        </div>
      </div>

      <div class="project_claimer_bott" *ngIf="isAvailable">
        <div class="project_claimer_bott_info">
          <div class="project_claimer_left">
            <div class="token-claimed">
              <p class="amount">{{claimedAmount | shiftDecimals: -lockInfo?.tokenDecimals}} {{lockInfo?.tokenSymbol}}
              </p>
              <p class="text">Claimed</p>
            </div>
            <div class="divider">/</div>
            <div class="token-all-allocation">
              <p class="amount">{{totalAmount | shiftDecimals: -lockInfo?.tokenDecimals}} {{lockInfo?.tokenSymbol}}</p>
              <p class="text">All allocation</p>
            </div>
          </div>

          <div class="project_claimer_right">
            <div class="token-available-to-claim">
              <p class="amount">{{isRefunded ? 0 : lockInfo.availableToClaim | shiftDecimals: -lockInfo?.tokenDecimals}}
                {{lockInfo?.tokenSymbol}}</p>
              <p class="text">Claimable</p>
            </div>
            <div class="divider">/</div>
            <div class="token-left">
              <p class="amount">{{isRefunded ? 0 : leftAmount | shiftDecimals: -lockInfo?.tokenDecimals}} {{lockInfo?.tokenSymbol}}</p>
              <p class="text">Left</p>
            </div>
          </div>
        </div>
        <div class="project_claimer_bott_progress">
          <progress max="100" [value]="claimedPercent"></progress>
        </div>
      </div>
    </div>

    <div class="refund-info-block" *ngIf="lockInfo?.refundType == 'partial' && (isConnected || isSolanaConnected || isTonConnected)">
        <p style="color:orange; margin: 0; text-align: center;"><a [href]="lockInfo?.url" target="_blank" style="text-decoration: underline; color:orange">This deal has been refunded.</a> Check your wallet for the <span style="text-decoration: underline;">{{lockInfo?.percentage}}% paid in stablecoins on {{lockInfo?.date | date : 'MMM dd, YYYY, HH:mm:ss' : '+0000'}} UTC!</span></p>
    </div>

    <div class="refund-info-block" *ngIf="lockInfo?.refundType == 'full' && (isConnected || isSolanaConnected || isTonConnected) && isRefunded">
      <p style="color:orange; margin: 0; text-align: center;"><span style="color:orange">You were refunded based on your request.</span> Check your wallet for the <span style="text-decoration: underline;">{{lockInfo?.percentage}}% paid in stablecoins on {{lockInfo?.date | date : 'MMM dd, YYYY, HH:mm:ss' : '+0000'}} UTC!</span></p>
    </div>

    <div class="project">
      <!-- <div class="project-top project-top_1fr">
              <div class="project-top-right">
                  <a href="#">All Locks</a>
                  <a href="#">My Locks</a>
                  <a href="#" class="act">Settings</a>
              </div>
          </div> -->

      <div *ngFor="let group of unlockGroups" class="all_clime">
        <div class="all_clime_item " [class.all_clime_item_period]="unlock.periodUnit && lockInfo.isUserParticipated"
          [class.all_clime_item_not_participated]="unlock.periodUnit && !lockInfo.isUserParticipated"
          *ngFor="let unlock of group" [ngClass]="{'project_activ': isConnected && canBeClaimed(unlock) && !unlock.isCanceled}">
          <!-- [ngClass]="{'all_clime_item_activ': unlockPassed(unlock.startDate), 'all_clime_item_end': false}" -->
          <div>
            <div class="t" *ngIf="unlock.percentage != 0">Vesting Starts</div>
            <div class="t" *ngIf="unlock.percentage == 0">Cliff Date</div>
            <p>{{unlock.startDate * 1000 | date:'dd MM YYYY HH:mm:ss':'+0000'}} UTC</p>
          </div>
          <div *ngIf="unlock.periodUnit">
            <div class="t">Vesting Ends</div>
            <p>{{unlock.endDate * 1000 | date:'dd MM YYYY HH:mm:ss':'+0000'}} UTC</p>
          </div>
          <div>
            <div class="t" *ngIf="unlock.percentage != 0">Claim percent</div>
            <div class="t" *ngIf="unlock.percentage == 0">---</div>
            <p *ngIf="unlock.percentage != 0 && (!unlock.periodUnit || !lockInfo.isUserParticipated)">
              {{unlock.percentage / (lockInfo.id <= 2 ? 100 : lockInfo?.blockchain=='solana' ? solanaPercentageDecimal :
                evmPercentageDecimal)}} % </p>
                <p *ngIf="unlock.percentage != 0 && (unlock.periodUnit && lockInfo.isUserParticipated)">
                  {{unlock.percentage / (lockInfo.id <= 2 ? 100 : lockInfo?.blockchain=='solana' ?
                    solanaPercentageDecimal : evmPercentageDecimal) / ((unlock.endDate - unlock.startDate +
                    unlock.periodUnit) / unlock.periodUnit) | number:'1.0-2'}} % </p>
          </div>
          <div *ngIf="unlock.periodUnit && lockInfo.isUserParticipated">
            <div class="t">Claimed</div>
            <p>
              {{calculateClaimed(unlock)}}% / {{unlock.percentage / (lockInfo.id <= 2 ? 100 :
                lockInfo?.blockchain=='solana' ? solanaPercentageDecimal : evmPercentageDecimal)}} % </p>
          </div>
          <div *ngIf="isAvailable && !isRefunded"
            (click)="(canBeClaimed(unlock) || unlock.canClaim) ? claimToken(lockInfo, unlock) : false"
            style="color:black">
            <a *ngIf="isAirdropped(unlock)">Airdropped</a>
            <!-- <a *ngIf="!lockInfo.isStopped && !isAirdropped(unlock) && unlock.percentage != 0 && !canBeClaimed(unlock) && !unlock.isClaimed">{{unlock.startDate*1000 | countdown | async}}</a> -->
            <a
              *ngIf="!lockInfo.isStopped && !isAirdropped(unlock) && unlock.percentage != 0 && !canBeClaimed(unlock) && !unlock.isCanceled && !unlock.isClaimed && unlock.timeLeft > 0 && !unlock.canClaim && (vestingStoppedTime <= 0 || !vestingStoppedTime)">
              <cd-timer [countdown]="true" [startTime]="unlock.timeLeft"
                (onComplete)="handleCountDownEndsEvent($event, unlock)"></cd-timer>
            </a>
            <a *ngIf="lockInfo.isStopped && !isAirdropped(unlock) && unlock.percentage != 0 && !canBeClaimed(unlock) && !unlock.isClaimed && unlock.isCanceled"
              (click)="alertCanceled()">Canceled</a>
            <a *ngIf="canBeClaimed(unlock) || unlock.canClaim" class="btn_claim">Claim all</a>
            <a *ngIf="!isAirdropped(unlock) && unlock.percentage != 0 && unlock.isClaimed">Claimed</a>
          </div>
          <a *ngIf="!isConnected && !isSolanaConnected && !isTonConnected" class="connect_wallet" (click)="connectWallet()">Connect Wallet</a>
          <a *ngIf="isRefunded" [ngClass]="{'btn_claim' : lockInfo?.refundType == 'full', 'disabled': !isConnected}" (click)="lockInfo?.refundType == 'full' && redirectRefunded()">Refunded</a>
        </div>
      </div>

      <div class="request_refund" *ngIf="isAvailable && isRefundVisible(lockInfo)">
        <p class="request_refund_deadline_text" *ngIf="!isTokenClaimed()">Deadline ends in {{ timeLeft.days }}d, {{ timeLeft.hours }}:{{ timeLeft.minutes }}:{{ timeLeft.seconds }}</p>
        <button [ngClass]="{ 'disable': isTokenClaimed() || isRefundRequested}" class="request_refund_btn"
          (click)="isTokenClaimed() ? showWarningPopup() : isRefundRequested ? null : requestRefund(lockInfo)">
          {{ isRefundRequested ? 'Request Refund Pending' : 'Request Refund' }}
        </button>
      </div>

      <div class="receiving_wallet" *ngIf="isAvailable && lockInfo?.id > 3 && claimedAmount != totalAmount && isRefundPast(lockInfo) && !isRefunded && !isSolanaConnected">
        <!--<div class="receiving_wallet_block">
                  <div class="t">Receiving Wallet</div>
                  <input type="text" placeholder="Enter new wallet here" [(ngModel)]="newWallet">
              </div>-->
        <button (click)="changeWallet(lockInfo)">Change Wallet</button>
      </div>
    </div>
  </div>
</section>
