import { Injectable, NgZone } from "@angular/core";
import { environment } from "src/environments/environment";
import { isWalletInfoCurrentlyInjected, TonConnect, TonConnectError, TonConnectUI, toUserFriendlyAddress } from "@tonconnect/ui";
import { AuthService } from "./auth.service";
import { BehaviorSubject, Observable, Subject } from "rxjs";
import { TonConnectStatus } from "../models/ton-connect-status";
import { AlertService } from "./alert.service";
import { address, Address, beginCell, Cell, Contract, Dictionary, fromNano, loadMessage, loadTransaction, OpenedContract, SenderArguments, toNano, TonClient } from "@ton/ton";
import { Token } from "./ton/Token";
import { JettonDefaultWallet, storeRequestRefund, storeSetRefunded, storeStopVesting, storeWithdraw } from "./ton/JettonDefaultWallet";
import { readContentFromCell } from "./ton/Metadata";
import { Claim, Claiming, CreateDistributor, storeChangeWallet, storeClaim, storeCreateDistributor, storeTokenTransfer, storeUpdateRefundDate, UnlockPeriod } from "./ton/Claiming";
import { ClaimEntry, flattenTransaction, generateRefundedDictionary, generateUnlocksDictionary, getEntryIndex, getErrorMessage, getMerkleProof, getMerkleRoot, getOpName } from "./ton/Utils";
import { Distributor } from "./ton/Distributor";
import { WalletDTO } from "../dto/wallet.dto";
import { PopupService } from "./popup.service";
import { LockDTO } from "../dto/lock.dto";

export const TON_TOKEN_PERCENTAGE_DECIMAL = 100000000;

@Injectable({ providedIn: 'root' })

export class TonService {
    constructor(
        private readonly ngZone: NgZone, 
        private readonly authService: AuthService, 
        private readonly alertService: AlertService,
        private readonly popupService: PopupService
    ) {
        //this.init();
    }

    client: TonClient;
    ui: TonConnectUI;
    public isConnected: boolean = false;
    DENOMINATOR: bigint = BigInt(100000000);

    private readonly currentAccount = new BehaviorSubject<string>('');
    public readonly currentAccount$ = this.currentAccount.asObservable();

    private readonly currentNetwork = new BehaviorSubject<string>('');
    public readonly currentNetwork$ = this.currentNetwork.asObservable();

    public get currentAccountValue(): string {
        return this.currentAccount.value;
    };

    public get currentNetworkValue(): string {
        return this.currentNetwork.value;
    }

    private statusSubject: Subject<TonConnectStatus> = new Subject<TonConnectStatus>();
    public status$: Observable<TonConnectStatus> = this.statusSubject.asObservable();

    public async init() {
        this.ui =  new TonConnectUI({
            manifestUrl: environment.ton.manifestUrl,
            buttonRootId: 'ton-connect'
        });

        this.ui.onStatusChange(wallet => {
            if (!wallet) {
                return;
            }

            this.isConnected = wallet?.account ? true : false;
            this.setAccount(toUserFriendlyAddress(wallet?.account?.address, environment.ton.testnet));
            this.setNetwork(wallet?.account?.chain);

            let proof = (wallet.connectItems?.tonProof as any)?.proof;
            if (proof) {
                proof.state_init = wallet.account.walletStateInit;
            }

            this.statusSubject.next({ 
                connected: this.isConnected, 
                account: this.currentAccount.value, 
                address: wallet?.account?.address,
                network: this.currentNetwork.value,
                public_key: wallet.account.publicKey,
                proof: proof
            });
        }, (error: TonConnectError) => {
            console.log('TonConnectError', error, error.message);
          }); 

        if (localStorage.getItem("ton-connect-storage_bridge-connection")) {
            await this.ui.connector.restoreConnection();
        }

        this.client = new TonClient({
            endpoint: environment.ton.endpoint,
            apiKey: environment.ton.apiKey
        });
    }

    public async showTonKeeperDialog() {
        const timeStamp = Math.floor(Date.now() / 1000);

        const wallets = await TonConnect.getWallets();
        const injectedWallets = wallets.filter(isWalletInfoCurrentlyInjected);
        const tonKeeperWallet = injectedWallets.find(x => x.appName == "tonkeeper");

        if (!tonKeeperWallet) {
            this.alertService.show("TonKeeper must be installed");
            return;
        }

        if (this.ui.connector.connected) {
            this.ui.connector.disconnect();
        }

        const universalLink = this.ui.connector.connect(
            { jsBridgeKey: tonKeeperWallet.jsBridgeKey }, 
            { tonProof: timeStamp.toString()}
        );
    }

    public async showTonConnectDialog() {
        const timeStamp = Math.floor(Date.now() / 1000);

        this.ui.setConnectRequestParameters({
            state: "ready",
            value: { tonProof: timeStamp.toString() }
        });

        await this.ui.openModal();
    }

    public logout(): void {
        this.authService.logout();
        this.ui.disconnect();
        this.setAccount(null);
        this.setNetwork(null);
    }

    private setAccount(account: string): void {
        if (account) {
            this.ngZone.run(() => {
                this.currentAccount.next(account);
                localStorage.setItem('tonAccount', account);
            });
        }
        else {
            localStorage.removeItem('tonAccount');
        }
    }

    private setNetwork(networkId: string): void {
        if (networkId) {
            this.ngZone.run(() => {
                this.currentNetwork.next(networkId);
                localStorage.setItem('tonNetwork', networkId);
            })
        }
        else {
            localStorage.removeItem('tonNetwork');
        }
    }


    public async getTokenInfo(tokenAddress: string) {
        const token: Token = Token.fromAddress(Address.parse(tokenAddress));
        const tokenContract = this.client.open(token);
        const tokenData = await tokenContract.getGetJettonData();
        const walletAddress = await tokenContract.getGetWalletAddress(Address.parse(this.currentAccountValue));
        const walletContract = this.client.open(JettonDefaultWallet.fromAddress(walletAddress));
        const walletData = await walletContract.getGetWalletData();
        const metadata = await readContentFromCell(tokenData.content);

        return { 
            tokenDecimal: parseInt(metadata.metadata.decimals), 
            tokenName: metadata.metadata.name, 
            tokenSymbol: metadata.metadata.symbol, 
            totalSupply: tokenData.total_supply, 
            balance: walletData.balance
        };
    }

    public async getAvailableAmount(vaultAddress: string): Promise<string> {
        const deployed = await this.client.isContractDeployed(Address.parse(vaultAddress));

        if (!deployed) {
            return "0";
        }
        else {
            var vaultContract = this.client.open(JettonDefaultWallet.fromAddress(Address.parse(vaultAddress)));
            var data = await vaultContract.getGetWalletData();
            return data.balance.toString();
        }
    }

    public async createClaiming(tokenAddress: string, claimingWallets: any[], schedule: any[], refundDate: number) {
        try {
            const op = await this.findOp('CreateDistributor');
            const queryId = BigInt(Date.now());
            var entries = this.claimingWalletsToEntries(claimingWallets);
            var totalAmount = entries.reduce((prev, cur) => prev + cur.amount, BigInt(0));
    
            var unlocks: UnlockPeriod[] = schedule.map(x => ({ 
                $$type: 'UnlockPeriod', 
                percentage: BigInt(x.percentage) * this.DENOMINATOR, 
                start: BigInt(x.startDate), 
                end: x.endDate ? BigInt(x.endDate) : BigInt(0),
                duration: x.periodUnit ? BigInt(x.periodUnit) : BigInt(0), 
                airdrop: x.isUnlockedBeforeStart? true : false
            }))
    
            const claiming = Claiming.fromAddress(Address.parse(environment.ton.claimingAddress));
            const claimingContract = this.client.open(claiming);
            const distributorsCount = await claimingContract.getDistributorsCount();
            const distributor = await Distributor.fromInit(claiming.address, distributorsCount + BigInt(1));
            var token = Token.fromAddress(Address.parse(tokenAddress));
            var tokenContract = this.client.open(token);
            var vaultAddress = await tokenContract.getGetWalletAddress(distributor.address);

            const message: CreateDistributor = {
                $$type: 'CreateDistributor',
                queryId: queryId,
                token: token.address,
                vault: vaultAddress,
                merkleRoot: getMerkleRoot(entries), 
                unlockPeriods: generateUnlocksDictionary(unlocks),
                totalAmount: totalAmount,
                refundDate: BigInt(Math.floor((refundDate || 0) / 1000))
            };
    
            const payload = beginCell().store(storeCreateDistributor(message)).endCell();
    
            const transaction = {
                validUntil: Math.floor(Date.now() / 1000) + 60, // 60 sec
                messages: [
                    {
                        address: environment.ton.claimingAddress,
                        amount: toNano(0.4).toString(),
                        payload: payload.toBoc().toString('base64')
                    }
                ]
            }
    
            var response = await this.ui.connector.sendTransaction(transaction);
            console.log('sendTransaction', response);
    
            // const code_cell = Cell.fromBoc(Buffer.from(response.boc, 'base64'))?.[0]
            // const msg = loadMessage(code_cell.beginParse());
            // console.log('sendTransaction message', msg);
    
            const tx = await this.waitForTransaction(this.currentAccountValue, environment.ton.claimingAddress, op, queryId);
            console.log('CreateDistributor Transaction', tx);

            if (!tx.success) {
                const message = await getErrorMessage(tx.exitCode);
                throw Error(message);
            }
            else {
                console.log('waiting for distributor at', distributor.address.toString({testOnly: environment.ton.testnet}));
                const distributorDetails = await this.waitForDistributor(distributor);
                console.log('distributor deployed', distributorDetails);

                return {
                    distributorAddress: distributor.address.toString({testOnly: environment.ton.testnet}),
                    vaultAddress: distributorDetails.vault.toString({testOnly: environment.ton.testnet})
                };
            }
        }
        catch(e: any) {
            if (!this.isRejected(e)) {
                console.log('claim error ', e);
                this.popupService.errorMessage(e.message);
            }

            return null;
        }
    }

    public async claimToken(distributorAddress: string, vaultAddress: string, wallets: Array<WalletDTO>, originalWallet: string = this.currentAccountValue): Promise<boolean> {
        let result = false;

        try {
            const op = await this.findOp('Claim');
            const queryId = BigInt(Date.now());
            const { merkleProof, index } = this.getProofData(wallets, originalWallet);

            const payload = beginCell().store(storeClaim({
                $$type: 'Claim',
                queryId: queryId,
                index: index,
                merkleProof: merkleProof
            })).endCell();

            const transaction = {
                validUntil: Math.floor(Date.now() / 1000) + 60, // 60 sec
                messages: [
                    {
                        address: distributorAddress,
                        amount: toNano(0.1).toString(),
                        payload: payload.toBoc().toString('base64')
                    }
                ]
            }

            var response = await this.ui.connector.sendTransaction(transaction);
            console.log('sendTransaction', response);

            const tx = await this.waitForTransaction(this.currentAccountValue, distributorAddress, op, queryId);
            console.log('Claim Transaction', tx);

            const bounceOp = await this.findOp('TokenTransfer');
            let bouncedTx: any = null;

            try {
                bouncedTx = await this.waitForBouncedTransaction(vaultAddress, distributorAddress, bounceOp, queryId);
            }
            catch {}

            console.log('Bounce Transaction', bouncedTx);

            if (tx.success && !bouncedTx) {
                this.popupService.successClaimPopupSimple();
                result = true;
            }
            else {
                const message = tx.exitCode != 0 ? await getErrorMessage(tx.exitCode) : 'TokenTrasfer failed'
                throw Error(message);
            }
        } catch (e: any) {
            if (!this.isRejected(e)) {
                console.log('claim error ', e);
                this.popupService.failureClaimPopupSimple(e.message);
            }
        }

        return result;
    }

    public async updateRefundDate(distributorAddress: string, refundDate: number) {
        try {
            const op = await this.findOp('UpdateRefundDate');
            const queryId = BigInt(Date.now());

            const payload = beginCell().store(storeUpdateRefundDate({
                $$type: 'UpdateRefundDate',
                queryId: queryId,
                refundDate: BigInt(refundDate)
            })).endCell();

            const transaction = {
                validUntil: Math.floor(Date.now() / 1000) + 60, // 60 sec
                messages: [
                    {
                        address: distributorAddress,
                        amount: toNano(0.1).toString(),
                        payload: payload.toBoc().toString('base64')
                    }
                ]
            }

            var response = await this.ui.connector.sendTransaction(transaction);
            console.log('sendTransaction', response);

            const tx = await this.waitForTransaction(this.currentAccountValue, distributorAddress, op, queryId);

            console.log('Update Refund Date Transaction', tx);

            if (tx.exitCode === 0) {
                this.popupService.successMessage("Refund Date updated successfully");
            }
            else {
                const message = await getErrorMessage(tx.exitCode);
                throw Error(message);
            }
        } catch (e: any) {
            console.log('Update Refund Date Error ', e);
            this.popupService.errorMessage(e.message);
        }
    }

    public async changeWallet(distributorAddress: string, wallets: Array<WalletDTO>, newWalletAddress: string) {
        try {
            const op = await this.findOp('ChangeWallet');
            const queryId = BigInt(Date.now());
            const { merkleProof, index } = this.getProofData(wallets, this.currentAccountValue);

            const payload = beginCell().store(storeChangeWallet({
                $$type: 'ChangeWallet',
                queryId: queryId,
                index: index,
                merkleProof: merkleProof,
                newWallet: Address.parse(newWalletAddress)
            })).endCell();

            const transaction = {
                validUntil: Math.floor(Date.now() / 1000) + 60, // 60 sec
                messages: [
                    {
                        address: distributorAddress,
                        amount: toNano(0.1).toString(),
                        payload: payload.toBoc().toString('base64')
                    }
                ]
            }

            var response = await this.ui.connector.sendTransaction(transaction);
            console.log('sendTransaction', response);

            const tx = await this.waitForTransaction(this.currentAccountValue, distributorAddress, op, queryId);

            console.log('Change Wallet Transaction', tx);

            if (tx.exitCode === 0) {
                this.popupService.successMessage("Wallet changed successfully");
                return true;
            }
            else {
                const message = await getErrorMessage(tx.exitCode);
                throw Error(message);
            }
        } catch (e: any) {
            if (!this.isRejected(e)) {
                console.log('Change Wallet Error ', e);
                this.popupService.errorMessage(e.message);
            }

            return false;
        }
    }

    public async requestRefund(distributorAddress: string, wallets: Array<WalletDTO>): Promise<any> {
        try {
            const op = await this.findOp('RequestRefund');
            const queryId = BigInt(Date.now());
            const { merkleProof, index } = this.getProofData(wallets, this.currentAccountValue);

            const payload = beginCell().store(storeRequestRefund({
                $$type: 'RequestRefund',
                queryId: queryId,
                index: index,
                merkleProof: merkleProof,
            })).endCell();

            const transaction = {
                validUntil: Math.floor(Date.now() / 1000) + 60, // 60 sec
                messages: [
                    {
                        address: distributorAddress,
                        amount: toNano(0.1).toString(),
                        payload: payload.toBoc().toString('base64')
                    }
                ]
            }

            var response = await this.ui.connector.sendTransaction(transaction);
            console.log('sendTransaction', response);

            const tx = await this.waitForTransaction(this.currentAccountValue, distributorAddress, op, queryId);

            console.log('Request Refund Transaction', tx);
            
            if (!tx.success) {
                const message = await getErrorMessage(tx.exitCode);
                throw Error(message);
            }
        } catch (e: any) {
            console.log('Request Refund Error ', e);
            throw(e);
        }
    }

    public async isRefundRequested(contractAddress: string): Promise<boolean> {
        const distributor = this.openDistributor(contractAddress);
        const userAddress = Address.parse(this.currentAccountValue);
        return await distributor.getIsRefundRequested(userAddress);
    }

    public async isRefunded(contractAddress: string): Promise<boolean> {
        const distributor = this.openDistributor(contractAddress);
        const userAddress = Address.parse(this.currentAccountValue);
        return await distributor.getIsRefunded(userAddress);
    }

    public async getAmountAvailableToClaim(
        distributorAddress: string,
        userWallet: string,
        total: string,
        tokenDecimal: number,
        lock: LockDTO
    ) {
        const distributor = this.openDistributor(distributorAddress);
        const { merkleProof, index } = this.getProofData(lock.claimingWallets, userWallet);
        const available = await distributor.getAvailableTokens(merkleProof, index);
        
        return available.toString();
    }

    async getTotalClaimedPerWallet(
        distributorAddress: string,
        claimingWallets: WalletDTO[],
        user: string = this.currentAccountValue
    ) {
        const distributor = this.openDistributor(distributorAddress);
        const { merkleProof, index } = this.getProofData(claimingWallets, user);
        const claimed = await distributor.getClaimedPerWallet(merkleProof, index);

        return claimed.toString();
    }

    public async hasStopped(lock: LockDTO) {
        const distributor = this.openDistributor(lock.contractAddress);
        const details =  await distributor.getDetails();
        return details?.stoppedTime > 0;
    }

    async stopVesting(distributorAddress: string, total: string, tokenDecimal: number) {
        try {
            const op = await this.findOp('StopVesting');
            const queryId = BigInt(Date.now());

            const payload = beginCell().store(storeStopVesting({
                $$type: 'StopVesting',
                queryId: queryId,
                balance: BigInt(total)
            })).endCell();

            const transaction = {
                validUntil: Math.floor(Date.now() / 1000) + 60, // 60 sec
                messages: [
                    {
                        address: distributorAddress,
                        amount: toNano(0.1).toString(),
                        payload: payload.toBoc().toString('base64')
                    }
                ]
            }

            var response = await this.ui.connector.sendTransaction(transaction);
            console.log('sendTransaction', response);

            const tx = await this.waitForTransaction(this.currentAccountValue, distributorAddress, op, queryId);

            console.log('Stop Vesting Transaction', tx);

            if (!tx.success) {
                const message = await getErrorMessage(tx.exitCode);
                throw Error(message);
            }
        } catch (e: any) {
            console.log('Stop Vesting Error ', e);
            throw(e);
        }
    }

    async depositFunds(distributorAddress: string, tokenAddress: string, amount: number) {
        try {
            const op = await this.findOp('TokenTransfer');
            const queryId = BigInt(Date.now());
            const sender = Address.parse(this.currentAccountValue);
            const recipient = Address.parse(distributorAddress);
            const wallet = await this.getWalletAddress(tokenAddress, this.currentAccountValue);
            const walletAddress = wallet.toString({ testOnly: environment.ton.testnet });
            const forwardPayload = beginCell().storeUint(0, 1).endCell().asSlice();
            
            const payload = beginCell().store(storeTokenTransfer({
                $$type: 'TokenTransfer',
                query_id: queryId,
                amount: BigInt(amount),
                destination: recipient,
                response_destination: sender,
                custom_payload: null,
                forward_ton_amount: BigInt(0),
                forward_payload: forwardPayload
            })).endCell();

            const transaction = {
                validUntil: Math.floor(Date.now() / 1000) + 60, // 60 sec
                messages: [
                    {
                        address: walletAddress,
                        amount: toNano(0.1).toString(),
                        payload: payload.toBoc().toString('base64')
                    }
                ]
            }

            var response = await this.ui.connector.sendTransaction(transaction);
            console.log('sendTransaction', response);

            const tx = await this.waitForTransaction(this.currentAccountValue, walletAddress, op, queryId);
            console.log('Deposit Transaction', tx);

            if (!tx.success) {
                const message = await getErrorMessage(tx.exitCode);
                throw Error(message);
            }
        }
        catch(e: any) {
            console.log('Deposit Error ', e);
            throw(e);
        }
    }

    async withdrawFunds(distributorAddress: string, amount: string) {
        try {
            const op = await this.findOp('Withdraw');
            const queryId = BigInt(Date.now());

            const payload = beginCell().store(storeWithdraw({
                $$type: 'Withdraw',
                queryId: queryId,
                amount: BigInt(amount)
            })).endCell();

            const transaction = {
                validUntil: Math.floor(Date.now() / 1000) + 60, // 60 sec
                messages: [
                    {
                        address: distributorAddress,
                        amount: toNano(0.1).toString(),
                        payload: payload.toBoc().toString('base64')
                    }
                ]
            }

            var response = await this.ui.connector.sendTransaction(transaction);
            console.log('sendTransaction', response);

            const tx = await this.waitForTransaction(this.currentAccountValue, distributorAddress, op, queryId);

            console.log('Withdraw Transaction', tx);

            if (!tx.success) {
                const message = await getErrorMessage(tx.exitCode);
                throw Error(message);
            }
        } catch (e: any) {
            console.log('Withdraw Error ', e);
            throw(e);
        }
    }

    async addRefunded(distributorAddress: string, addresses: string[]): Promise<any>{
        try {
            const op = await this.findOp('SetRefunded');
            const queryId = BigInt(Date.now());
            const refundedAddresses = addresses.map(x => Address.parse(x));
            const refunded = generateRefundedDictionary(refundedAddresses, true);

            const payload = beginCell().store(storeSetRefunded({
                $$type: 'SetRefunded',
                queryId: queryId,
                refunded: refunded
            })).endCell();

            const transaction = {
                validUntil: Math.floor(Date.now() / 1000) + 60, // 60 sec
                messages: [
                    {
                        address: distributorAddress,
                        amount: toNano(0.1).toString(),
                        payload: payload.toBoc().toString('base64')
                    }
                ]
            }

            var response = await this.ui.connector.sendTransaction(transaction);
            console.log('sendTransaction', response);

            const tx = await this.waitForTransaction(this.currentAccountValue, distributorAddress, op, queryId);

            console.log('Set Refunded Transaction', tx);

            if (!tx.success) {
                const message = await getErrorMessage(tx.exitCode);
                throw Error(message);
            }
        } catch (e: any) {
            console.log('Set Refunded Error ', e);
            throw(e);
        }
    }

    async getRefundRequests(distributorAddress: string): Promise<any[]> {
        const distributor = this.openDistributor(distributorAddress);
        const refundRequests = await distributor.getRefundRequests();

        let result: any[] = [];
        for (const item of refundRequests) {
            if (item[1] === true) {
                result.push(item[0].toString({ testOnly: environment.ton.testnet, bounceable: false }));
            }
        }

        return result;
    }

    async waitForDistributor(distributor: Distributor): Promise<{
        $$type: "DistributorDetails";
        id: bigint;
        token: Address;
        vault: Address;
        merkleRoot: bigint;
        unlockPeriods: Dictionary<bigint, UnlockPeriod>;
        totalAmount: bigint;
        totalClaimedAmount: bigint;
        refundDate: bigint;
        stoppedTime: bigint;
    }> {
        return this.retry(async () => {
            const deployed = await this.client.isContractDeployed(distributor.address);

            if (deployed) {
                const distributorContract = this.client.open(distributor);
                return await distributorContract.getDetails();
            }
            
            throw new Error('Distributor not deployed');
        }, { retries: 60, delay: 1000 });
    }

    async waitForTransaction(from: string, to: string, op: number, queryId: bigint) {
        const contract = Address.parse(to);
        const sender = Address.parse(from);

        console.log(`Waiting for tx from ${this.formatAddress(sender)} to ${this.formatAddress(contract)}, op: ${op}, query id: ${queryId}`);

        return this.retry(async () => {
            let result = null;
            const transactions = await this.client.getTransactions(contract, { limit: 5, inclusive: true, archival: true});
            const flattened = transactions.map(x => flattenTransaction(x)).filter(x => x.queryId == queryId);
            
            for (const tx of flattened) {
                //this.logTransaction(tx);
                if (tx.from && tx.from.equals(sender) && tx.op === op && !tx.inMessageBounced) {
                    result = tx;
                }
            }

            if (!result) {
                throw new Error('Transaction not found');
            }

            return result;

        }, { retries: 60, delay: 1000 });
    }

    async waitForBouncedTransaction(from: string, to: string, op: number, queryId: bigint) {
        const contract = Address.parse(to);
        const sender = Address.parse(from);

        console.log(`Waiting for bounced tx from ${this.formatAddress(sender)} to ${this.formatAddress(contract)}, op: ${op}, query id: ${queryId}`);

        return this.retry(async () => {
            let result = null;
            const transactions = await this.client.getTransactions(contract, { limit: 5, inclusive: true, archival: true});
            const flattened = transactions.map(x => flattenTransaction(x)).filter(x => x.queryId == queryId);

            for (const tx of flattened) {
                //this.logTransaction(tx);
                if (tx.from && tx.from.equals(sender) && tx.op === op && tx.inMessageBounced) {
                    result = tx;
                }
            }

            if (!result) {
                throw new Error('Transaction not found');
            }

            return result;

        }, { retries: 30, delay: 1000 });
    }

    async retry<T>(fn: () => Promise<T>, options: { retries: number, delay: number }): Promise<T> {
        let lastError: Error | undefined;
        for (let i = 0; i < options.retries; i++) {
            try {
                return await fn();
            } catch (e) {
                if (e instanceof Error) {
                    lastError = e;
                }
                await new Promise(resolve => setTimeout(resolve, options.delay));
            }
        }
        throw lastError;
    }

    private async findOp(name: string): Promise<number> {
        const types = (await Claiming.fromInit()).abi.types;
        const op = types?.find(x => x.name === name)?.header;

        if (op) {
            return op;
        }

        throw new Error("Op not found");
    }

    
    private openDistributor(address: string) : OpenedContract<Distributor> {
        const distributor: Distributor = Distributor.fromAddress(Address.parse(address));
        return this.client.open(distributor);
    }

    private async getWalletAddress(tokenAddress: string, ownerAddress: string): Promise<Address> {
        var token = Token.fromAddress(Address.parse(tokenAddress));
        var tokenContract = this.client.open(token);
        var walletAddress = await tokenContract.getGetWalletAddress(Address.parse(ownerAddress));
        return walletAddress;
    }

    private getProofData(claimingWallets: any[], user: string) {
        const originalAddress = claimingWallets.find(x => x.address == user)?.originalAddress;
        if (originalAddress) {
            user = originalAddress;
        }
        
        var entries = this.claimingWalletsToEntries(claimingWallets);
        const index = getEntryIndex(entries, Address.parse(user));

        if (index == BigInt(-1)) {
            throw Error("Address not found in claiming wallets");
        }

        const merkleProof = getMerkleProof(entries, index);

        return { merkleProof, index };
    }

    private claimingWalletsToEntries(claimingWallets: any[]): ClaimEntry[] {
        const wallets = claimingWallets.map(x => ({
            address: x.originalAddress || x.address, 
            amount: x.amount 
        }));

        return wallets
            .sort((a, b) => a.address.localeCompare(b.address, 'en'))
            .map(x => ({
                address: Address.parse(x.address), 
                amount: BigInt(x.amount) 
            })
        );
    }

    private formatAddress(address: Address, shorten: boolean = false): string {
        let result = address.toString({testOnly: environment.ton.testnet});

        if (shorten && result.length > 20) {
            result = result.substring(0, 6) + '...' + result.substring(result.length - 6, result.length);
        }

        return result;
    }

    private isRejected(e: any) {
        return e?.message?.indexOf('Reject request') != -1; 
    }

    private logTransaction(tx: any) {
        console.log(`${tx.lt} ${this.formatAddress(tx.from, true)} -> ${this.formatAddress(tx.to as Address, true)}, bounced: ${tx.inMessageBounced}, op: ${tx.op}, query_id: ${tx.queryId}`);
    }
}