import {UnlockDTO} from "./unlock.dto";
import {WalletDTO} from "./wallet.dto";
import {PlatformDTO} from "./platform.dto";
import {LockDataDto} from "./lock-data.dto";
import {BigNumber} from 'bignumber.js';

export class LockDTO {
  readonly id?: number;
  readonly blockchain: string;
  readonly blockchainId: number; // 0: Ethereum, 1: BSC, 2: Polygon, 3: Solana
  readonly version: number; // 0: first version, 1: second version with stop/pause feature
  readonly platform: PlatformDTO;
  readonly platformId: number;
  readonly tokenAddress: string;
  readonly tokenName: string;
  readonly tokenSymbol: string;
  readonly tokenDecimals: number;
  readonly contractAddress?: string;
  readonly projectName: string;
  readonly projectPrice: string;
  readonly projectIcon: string;
  readonly amount: string;
  readonly vestingType: string;
  readonly vestingTypeId: number; // 0: Linear, 1: NonLinear, 2: Custom
  readonly startDate: number;
  readonly endDate: number;
  readonly refundDate?: number;
  unlocks: UnlockDTO[];
  claimingWallets: WalletDTO[];
  readonly networkId: number;
  readonly isHidden?: boolean;
  readonly vaultAddress?: string;

  availableToClaim?: string;
  transactionHash: string;
  nextUnlock?: number;
  isLoading?: boolean;
  isStopped: boolean;

  proof?:string [];
  isUserParticipated: boolean;
  userTotalAllocation: string;
  isRefunded: boolean;
  timeLeft: number;
  readonly refundType: 'full'| 'partial';
  readonly date: Date;
  readonly url: string;
  readonly percentage: number;
  readonly coin: "busd" | "usdt";
  
  public get claimable(): boolean{
    return this.availableToClaim && this.availableToClaim != "0";
  }

  public get isV2(): boolean {
    return this.startDate > 1708473600000;
  }

  public get allocationReduced(): number { return LockDTO.reduce(this.userTotalAllocation, this.tokenDecimals) };
  public get amountReduced(): number { return LockDTO.reduce(this.amount, this.tokenDecimals) };
  public get claimableReduced(): number { return !this.isRefunded ? LockDTO.reduce(this.availableToClaim, this.tokenDecimals) : 0 };

  public static reduce(value: any, decimals: number): number {
    const result = new BigNumber(value).shiftedBy(-decimals).toNumber();
    return isNaN(result) ? 0 : result;
  }

  public assignData(data: LockDataDto): void {
    this.claimingWallets = data.claimingWallets;
    this.unlocks = data.unlocks;
  }
 
  public checktimeLeft(): void {
    const currentDate = new Date();
    const currentTime = currentDate.getTime();
    const futureUnlocks  = this.unlocks.filter(i=> i.startDate*1000 > currentTime).sort((a, b) => a.id > b.id ? 1 : -1);
    const currentProgressUnlock = this.unlocks.filter(i=> i.periodUnit && i.startDate * 1000 <= currentTime && i.endDate * 1000 >= currentTime);
    //console.log('futureUnlocks', this.unlocks, futureUnlocks, this.id, this.projectName)
    const nextUnlock = futureUnlocks[0] || currentProgressUnlock[0];
    if(nextUnlock?.periodUnit && currentTime > nextUnlock.startDate * 1000){
      const currentDiff = nextUnlock.periodUnit - (((currentTime - (nextUnlock.startDate * 1000)) / 1000) % nextUnlock.periodUnit);
      this.timeLeft =  currentDiff;
    }
    else{
      this.timeLeft =  ((nextUnlock?.startDate * 1000) - currentTime) / 1000;
    }
  }
}
