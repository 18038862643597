<section class="selector_project">
  <div class="container">
    <div>
      <label>Token address</label>
      <input type="text" [(ngModel)]="tokenIdentifier" />
    </div>
    <div>
      <label>Enter user addresses and amounts using ',' or ';' as separators. Example:</label>
      <pre>
erd1eet79zg6z88nk2xd8nx026yg483wzsz0wende98j4543m6mnfa8sxjzuyq,100000000000000000000
erd17h9dw9w62mqxs20cnmqrrnmnr4zx4y322cd6ywjlrjgux53472hqchm86u,500000000000000000000
      </pre>
      <textarea [(ngModel)]="userAddressesToCount"
                rows="10"
                cdkTextareaAutosize
                cdkAutosizeMaxRows="22">
      </textarea>
    </div>

    <div>
      <button (click)="sendTokens()">Send</button>
    </div>

    <div *ngIf="transactionHash">
      Transaction created: <a target="_blank" href="{{transactionUrl}}">{{transactionUrl}}</a>
    </div>
  </div>
</section>
