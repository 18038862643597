import { Component, HostListener } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthService } from 'src/app/services/auth.service';
import { Web3Service } from 'src/app/services/web3.service';
import { SolanaWeb3Service } from 'src/app/services/solana.service';
import { MatDialog } from "@angular/material/dialog";
import { NoopScrollStrategy } from "@angular/cdk/overlay";
import { SelectWalletComponent } from "../dialogs/select-wallet/select-wallet.component";
import { ElrondService } from "../../services/elrond.service";
import { TonService } from 'src/app/services/ton.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent {

  public show: boolean = false;

  public get isNewLockAvailable(): boolean {
    const connected = this.isConnected || this.isSolanaConnected || this.isTonConnected;
    return connected && this.auth.isAdmin();
  }

  public get isConnected(): boolean {
    return this.web3.isConnected && this.auth.isAuthorized();
  }

  public get isSolanaConnected(): boolean{
    return this.solana.isConnected && this.auth.isAuthorized();
  }

  public get isElrondConnected(): boolean{
    return this.elrond.isConnected && this.auth.isAuthorized();
  }

  public get isTonConnected(): boolean {
    return this.ton.isConnected && this.auth.isAuthorized();
  }

  @HostListener('document:click', ['$event']) onDocumentClick() {
    this.show = false;
  }

  public currentAccount: Observable<string> = this.web3.currentAccount$;
  public currentSolanaAccount: Observable<string> = this.solana.currentAccount$;
  public currentElrondAccount: Observable<string> = this.elrond.currentAccount$;
  public currentTonAccount: Observable<string> = this.ton.currentAccount$;
  public currentNetwork: Observable<number> = this.web3.currentNetwork$.pipe(map(i => parseInt(i, 16)));

  constructor(
    private readonly web3: Web3Service,
    private readonly solana: SolanaWeb3Service,
    private readonly elrond: ElrondService,
    private readonly ton: TonService,
    private dialog: MatDialog,
    private readonly auth: AuthService
  ) { }

  public async connectWallet(): Promise<void> {
    const dialogRef = this.dialog.open(SelectWalletComponent, {
      scrollStrategy: new NoopScrollStrategy()
    });
  }

  public disconnect(): void {
    this.web3.logout();
    this.solana.logout();
    this.elrond.logout();
    this.ton.logout();
  }

  public toggleNetworkChange(event: any): void {
    this.show = !this.show;
    event.stopPropagation();

  }

  public changeNetwork(id: number): void {
    this.web3.changeNetwork(id);
  }
}
