import { Injectable, NgZone } from "@angular/core";
import { BehaviorSubject, combineLatest, Observable, Subject } from "rxjs";
import {
    Connection,
    clusterApiUrl,
    ConfirmOptions,
    PublicKey,
} from '@solana/web3.js';
import { keccak256 } from 'ethereumjs-util';
import * as spl from '@solana/spl-token';
import { Metadata } from "@metaplex-foundation/mpl-token-metadata";
import { TokenListProvider } from '@solana/spl-token-registry';
import Wallet from '@project-serum/sol-wallet-adapter';
import * as anchor from '@project-serum/anchor';
import * as serumCmn from "@project-serum/common";
import { BigNumber } from 'bignumber.js';
import { TokenInstructions } from '@project-serum/serum';
import { environment } from 'src/environments/environment';

import idl from '../../assets/abi/ClaimingFactorySolana.json';
import * as ty from '../../assets/type/claiming_factory';
import { WalletDTO } from "../dto/wallet.dto";
import { PopupService } from "./popup.service";
import { AuthService } from "./auth.service";
import { LockDTO } from "../dto/lock.dto";

declare let window: any;

const TOKEN_PROGRAM_ID = TokenInstructions.TOKEN_PROGRAM_ID;

const programId = environment.solana.claimingProgramId;

type Opts = {
    preflightCommitment: anchor.web3.Commitment,
}
const opts: Opts = {
    preflightCommitment: 'processed'
}

type NetworkName = anchor.web3.Cluster | string;

export const LOCALNET = 'http://127.0.0.1:8899';
export const DEVNET = 'devnet';
export const TESTNET = 'testnet';
export const MAINNET = 'mainnet-beta';
export const SOLANA_TOKEN_PERCENTAGE_DECIMAL = 1000000000;

const LOCALNET_PROGRAM_ID = programId;
const DEVNET_PROGRAM_ID = programId;
// TODO: change address to actual testnet program address
const TESTNET_PROGRAM_ID = programId;
// TODO: change address to actual mainnet program address
const MAINNET_PROGRAM_ID = programId;

export type CreateDistributorArgs = {
    mint: anchor.web3.PublicKey,
    merkleRoot: number[],
};

export type Period = {
    tokenPercentage: anchor.BN,
    startTs: anchor.BN,
    intervalSec: anchor.BN,
    times: anchor.BN,
    airdropped: boolean,
};

export type UserDetails = {
    lastClaimedAtTs: anchor.BN,
    claimedAmount: anchor.BN,
    bump: number,
};

const FAILED_TO_FIND_ACCOUNT = "Account does not exist";
const INVALID_ACCOUNT_OWNER = 'Invalid account owner';

@Injectable({ providedIn: 'root' })

export class SolanaWeb3Service {
    provider: anchor.AnchorProvider;
    networkName: NetworkName;
    serumProvider: serumCmn.Provider;
    program: anchor.Program<ty.ClaimingFactory>;

    constructor(private readonly ngZone: NgZone, private popupService: PopupService, private readonly authService: AuthService) {
        const account = localStorage.getItem('accountAddressSolana');
        const network = localStorage.getItem('networkIdSolana');
        const walletType = localStorage.getItem('walletType');
        if (account && network) {
            if (walletType == 'solflare') {
                const solflare = (window as any).solflare;
                setTimeout(() => { this.initWallet(account, network, solflare) }, 100)
                return;
            }
            if (walletType == 'sollet') {
                const sollet = (window as any).sollet;
                let providerUrl = 'https://www.sollet.io';
                if (!sollet) {
                    console.log('???')
                    setTimeout(() => {
                        const sollet = (window as any).sollet;
                        this.initSollet(sollet, providerUrl, account, network);
                    }, 100);
                }
                else {
                    this.initSollet(sollet, providerUrl, account, network);
                }
                return;
            }
            if (walletType == 'phantom') {
                setTimeout(() => {
                    const solana = (window as any).solana;
                    this.initWallet(account, network, solana);
                }, 100);
                return;
            }
        }
    }

    private initSollet(sollet: any, providerUrl: any, account: any, network: any): void {
        let wallet = new Wallet(sollet, providerUrl);
        this.initWallet(account, network, wallet);
    }

    private readonly currentAccount = new BehaviorSubject<string>('');
    public readonly currentAccount$ = this.currentAccount.asObservable();

    public isConnected: boolean = false;

    private readonly currentNetwork = new BehaviorSubject<string>('');
    public readonly currentNetwork$ = this.currentNetwork.asObservable();

    connection: anchor.web3.Connection;
    anchor: any = anchor;
    private currentProvider: any;

    public initWallet(address: any, network: string, provider?: any) {
        this.currentProvider = provider;
        this.setAccount(address);
        this.setNetwork(network);
        this.isConnected = true;
        this.networkName = network;
        this.connection = new Connection(
            environment.solana.rpcUrls[0],
            'confirmed',
        );
        this.provider = this.getProvider(provider) as any;
        this.program = this.initProgram();
        this.serumProvider = new serumCmn.Provider(this.provider.connection, this.provider.wallet, opts);
    }

    public logout(): void {
        this.authService.logout();
        localStorage.removeItem('accountAddressSolana');
        localStorage.removeItem('networkIdSolana');
        this.setAccount('');
        this.setNetwork('');
        localStorage.removeItem('walletType');
        this.isConnected = false;
    }

    private setAccount(account: string): void {
        this.ngZone.run(() => {
            this.currentAccount.next(account);
            localStorage.setItem('accountAddressSolana', account);
        })
    }

    private setNetwork(networkId: string): void {
        this.ngZone.run(() => {
            this.currentNetwork.next(networkId);
            localStorage.setItem('networkIdSolana', networkId);
        })
    }


    public toHexString(byteArray: any) {
        return Array.from(byteArray, function (byte: any) {
            return ('0' + (byte & 0xFF).toString(16)).slice(-2);
        }).join('')
    }


    public get currentAccountValue(): string {
        return this.currentAccount.value;
    };

    public get currentNetworkValue(): string {
        return this.currentNetwork.value;
    }

    /**
     * Creates the provider and returns it to the caller
     * @param {anchor.Wallet} wallet - the solana wallet
     * @returns {anchor.Provider} Returns the provider
     */
    // getProvider(wallet: anchor.Wallet): anchor.AnchorProvider {
    // let network: string = '';
    // switch (this.networkName) {
    //     case DEVNET:
    //     case TESTNET:
    //     case MAINNET:
    //         network = anchor.web3.clusterApiUrl(this.networkName);
    //         break;
    //     case LOCALNET:
    //         network = this.networkName;
    // }

    // const connection = new anchor.web3.Connection(network, opts.preflightCommitment);
    // const provider = new anchor.AnchorProvider(connection, wallet, opts);
    // return provider;

    // }

    getProvider(data: any) {
        try {
            const provider = new anchor.AnchorProvider(this.connection, data, 'processed' as ConfirmOptions);
            return provider;
        } catch (e) {
            console.error('getProvider:', e);
            return null;
        }
    }

    /**
     * Initializes the program using program's idl for every network
     * @returns {anchor.Program} Returns the initialized program
     */
    // initProgram(): anchor.Program<ty.ClaimingFactory> {
    initProgram(): anchor.Program<ty.ClaimingFactory> {
        switch (this.networkName) {
            case LOCALNET:
                return new anchor.Program(idl as anchor.Idl, LOCALNET_PROGRAM_ID, this.provider) as any;
            case DEVNET:
                return new anchor.Program(idl as anchor.Idl, DEVNET_PROGRAM_ID, this.provider) as any;
            case TESTNET:
                return new anchor.Program(idl as anchor.Idl, TESTNET_PROGRAM_ID, this.provider) as any;
            case MAINNET:
                return new anchor.Program(idl as anchor.Idl, MAINNET_PROGRAM_ID, this.provider) as any;
            default:
                return new anchor.Program(idl as anchor.Idl, DEVNET_PROGRAM_ID, this.provider) as any;
        }
    }

    /**
     * Find a valid program address of config account
     * @returns {Promise<[anchor.web3.PublicKey, number]>} Returns the public key of config and the bump number
     */
    async findConfigAddress(): Promise<[anchor.web3.PublicKey, number]> {
        const [config, bump] = await anchor.web3.PublicKey.findProgramAddress(
            [
                new TextEncoder().encode("config")
            ],
            this.program.programId,
        );
        return [config, bump];
    }

    /**
     * Initializes config
     * @returns {Promise<anchor.web3.PublicKey>} Returns the public key of config
     */
    async createConfig() {
        const [config, bump] = await this.findConfigAddress();

        await this.program.rpc.initializeConfig(
            bump,
            {
                accounts: {
                    config,
                    owner: this.provider.wallet.publicKey,
                    systemProgram: anchor.web3.SystemProgram.programId,
                }
            }
        );

        return config;
    }

    async getTokenInfo(tokenAddress: string) {
        try {
            let tokenName = '';
            let tokenSymbol = '';

            const token = await this.withConnectionRetry(
                () => Promise.resolve(new spl.Token(this.connection, new PublicKey(tokenAddress), spl.TOKEN_PROGRAM_ID, undefined)));
            const mintInfo = await token.getMintInfo();
            const tokenDecimal = mintInfo.decimals;
            const totalSupply = mintInfo.supply;

            // const vaults = await this.fetchDistributorData();
            let balance = new BigNumber('0');
            // for (let index = 0; index < vaults.length; index++) {
            //     let data = await this.connection.getTokenAccountBalance(new PublicKey(vaults[index]));
            //     balance = balance.plus(new BigNumber(data.value.amount));
            // }

            // **** get Token MetaData **** //
            let tokenList = await new TokenListProvider().resolve();
            let tokens = tokenList.filterByClusterSlug(this.networkName).getList();
            let filtered = tokens.filter((item: any) => item.address == tokenAddress);
            if (filtered.length > 0) {
                tokenName = filtered[0].name;
                tokenSymbol = filtered[0].symbol;
            } else { // get Metadata by Metaplex
                let metadata = await this.withConnectionRetry(() => Metadata.findByMint(this.connection, new PublicKey(tokenAddress)));
                tokenName = metadata.data.data.name;
                tokenSymbol = metadata.data.data.symbol;
            }

            return { tokenDecimal, tokenName, tokenSymbol, totalSupply, balance }

            // *** My wallets balance
            // const tokenAccounts = await this.connection.getTokenAccountsByOwner(
            //     new PublicKey('EkphYPH8TrkfNgXot6JkZSGXvodkpZK5fwmd1iMYijWJ'),
            //     // new PublicKey('7PoA4FFQSrBMkkW67odfNefH7VQHeJSfEs3ni4qTo3UH'), //second wallet
            //     // new PublicKey(programId),
            //     {
            //         programId: spl.TOKEN_PROGRAM_ID,
            //     }
            // );

            // let balance = new BigNumber('0');
            // tokenAccounts.value.forEach((e) => {
            //     const accountInfo = spl.AccountLayout.decode(e.account.data);
            //     let mintToken = (new PublicKey(accountInfo.mint)).toString();
            //     console.log('mintToken >>>>', accountInfo);
            //     if (mintToken == tokenAddress) balance = balance.plus(spl.u64.fromBuffer(accountInfo.amount).toString())
            // });
            // *** My wallets balance
        }
        catch (e: any) {
            console.log('error getTokenInfo >>>', e);
            return null;
        }
    }

    async getTokenAccount(mintToken: string, wallet: string) {
        const associatedTokenAccount = await spl.Token.getAssociatedTokenAddress(
            spl.ASSOCIATED_TOKEN_PROGRAM_ID,
            spl.TOKEN_PROGRAM_ID,
            new PublicKey(mintToken),
            new PublicKey(wallet)
        );
        console.log(`>>>>> ${new PublicKey(associatedTokenAccount)}`);
        return associatedTokenAccount;
    }

    /**
     * Find a program address of vault authority
     * @param {anchor.web3.PublicKey} distributor - public key of distributor
     * @returns {Promise<[anchor.web3.PublicKey, number]>} Returns the public key of vault authority and the bump number
     */
    async findVaultAuthority(distributor: anchor.web3.PublicKey): Promise<[anchor.web3.PublicKey, number]> {
        const [vaultAuthority, vaultBump] = await anchor.web3.PublicKey.findProgramAddress(
            [
                distributor.toBytes()
            ],
            this.program.programId,
        );
        return [vaultAuthority, vaultBump];
    }

    async findRefundRequestAddress(
        distributor: anchor.web3.PublicKey,
        user: anchor.web3.PublicKey
    ): Promise<[anchor.web3.PublicKey, number]> {
        const [refundRequest, bump] = await anchor.web3.PublicKey.findProgramAddress(
            [
                distributor.toBytes(),
                user.toBytes(),
                new TextEncoder().encode("refund-request"),
            ],
            this.program.programId
        );

        return [refundRequest, bump];
    }

    /**
     * Initializes distributor
     * @param {anchor.web3.PublicKey} mint - public key of mint to distibute
     * @param {number[]} merkleRoot
     * @param {Period[]} schedule - token distribution data (amount, time)
     * @returns {Promise<anchor.web3.PublicKey>} Returns the public key of newly created distributor
     */
    async createDistributor(mint: anchor.web3.PublicKey, merkleRoot: number[], schedule: Period[], refundDate?: anchor.BN): Promise<anchor.web3.PublicKey> {
        // no more than 18 periods in initialize ix
        if (schedule.length >= 18) {
            const distributor = await this.createDistributorLarge(mint, merkleRoot, schedule.length, refundDate);
            const changes = schedule.map(p => ({ push: { period: p } }));
            let start = 0;
            while (start < schedule.length) {
                // no more than 27 periods in update_schedule2 ix
                const changesSlice = changes.slice(start, start + 27);
                await this.updateScheduleUnchecked(distributor, changesSlice);
                start += 27;
            }
            return distributor;
        }

        const distributor = anchor.web3.Keypair.generate();
        const [vaultAuthority, vaultBump] = await this.findVaultAuthority(distributor.publicKey);
        const [config, _bump] = await this.findConfigAddress();

        const vault = anchor.web3.Keypair.generate();
        const createTokenAccountInstrs = await serumCmn.createTokenAccountInstrs(
            this.program.provider as serumCmn.Provider,
            vault.publicKey,
            mint,
            vaultAuthority
        );

        console.log('this.programId >>>', this.program.programId.toBase58());
        console.log('this.program >>>', this.program);
        console.log('this.program.provider >>>', this.program.provider);
        console.log('this.provider.wallet >>>', this.provider.wallet);

        await this.program.rpc.initialize(
            {
                vaultBump,
                merkleRoot,
                schedule,
                //refundDeadlineTs: refundDate
            },
            {
                accounts: {
                    distributor: distributor.publicKey,
                    adminOrOwner: this.provider.wallet.publicKey,
                    vaultAuthority,
                    vault: vault.publicKey,
                    config,
                    systemProgram: anchor.web3.SystemProgram.programId,
                },
                instructions: createTokenAccountInstrs,
                signers: [vault, distributor]
            }
        );

        return distributor.publicKey;
    }

    /**
     * Initializes distributor with schedule larger than 18 periods
     * @param {anchor.web3.PublicKey} mint - public key of mint to distibute
     * @param {number[]} merkleRoot
     * @param {number} periodsCount
     * @returns {Promise<anchor.web3.PublicKey>} Returns the public key of newly created distributor
     */
    async createDistributorLarge(mint: anchor.web3.PublicKey, merkleRoot: number[], periodsCount: number, refundDate?: anchor.BN): Promise<anchor.web3.PublicKey> {
        const distributor = anchor.web3.Keypair.generate();
        const [vaultAuthority, vaultBump] = await this.findVaultAuthority(distributor.publicKey);
        const [config, _bump] = await this.findConfigAddress();

        const vault = anchor.web3.Keypair.generate();
        const createTokenAccountInstrs = await serumCmn.createTokenAccountInstrs(
            this.program.provider as serumCmn.Provider,
            vault.publicKey,
            mint,
            vaultAuthority
        );

        await this.program.rpc.initialize2(
            {
                vaultBump,
                merkleRoot,
                periodsCount: new anchor.BN(periodsCount),
                //refundDeadlineTs: refundDate
            },
            {
                accounts: {
                    distributor: distributor.publicKey,
                    adminOrOwner: this.provider.wallet.publicKey,
                    vaultAuthority,
                    vault: vault.publicKey,
                    config,
                    systemProgram: anchor.web3.SystemProgram.programId,
                },
                instructions: createTokenAccountInstrs,
                signers: [vault, distributor]
            }
        );

        return distributor.publicKey;
    }

    /**
     * Updates shedule (should be used for schedules larger than 18 periods)
     * @param {anchor.web3.PublicKey} distributor - public key of distributor, on which tokes were claimed
     * @param {any[]} changes - new shedule data
     */
    async updateScheduleUnchecked(distributor: anchor.web3.PublicKey, changes: any[]) {
        const [config, _bump] = await this.findConfigAddress();
        await this.program.rpc.updateSchedule2(
            {
                changes
            },
            {
                accounts: {
                    distributor,
                    config,
                    adminOrOwner: this.provider.wallet.publicKey,
                    clock: anchor.web3.SYSVAR_CLOCK_PUBKEY,
                }
            }
        );
    }

    async createClaiming(mint: string, claimingWallets: any, schedule: any, refundDate: number): Promise<any> {
        try {
            const { root } = this.getMerkleProof(claimingWallets);
            const refundDeadline = refundDate ? new anchor.BN(Math.floor(refundDate / 1000)) : null;
 
            const distributor = await this.createDistributor(
                new PublicKey(mint),
                root,
                schedule,
                refundDeadline
            );
            console.log('distributor base58 >>>>>>', distributor.toBase58());
            const distributorAccount = await this.program.account.merkleDistributor.fetch(distributor);
            console.log('vault base58 >>>>>>', distributorAccount.vault.toBase58());
            return {
                distributorAddress: distributor.toBase58(),
                vaultAddress: distributorAccount.vault.toBase58()
            };
        } catch (e) {
            console.error(e);
            return null;
        }
    }

    async updateClaimingSchedule(distributor: string) {

        try {
            let schedule = [{
                startTs: new anchor.BN(1658707200),
                tokenPercentage: new anchor.BN(40 * SOLANA_TOKEN_PERCENTAGE_DECIMAL),
                times: new anchor.BN(1),
                intervalSec: new anchor.BN(1),
                airdropped: false
            },
            {
                startTs: new anchor.BN(1659484800),
                tokenPercentage: new anchor.BN(60 * SOLANA_TOKEN_PERCENTAGE_DECIMAL),
                times: new anchor.BN(1),
                intervalSec: new anchor.BN(1),
                airdropped: false
            }];

            await this.updateSchedule(new PublicKey(distributor), [
                {
                    update: {
                        index: new anchor.BN(0),
                        period: schedule[0]
                    }
                },
                {
                    update: {
                        index: new anchor.BN(1),
                        period: schedule[1]
                    }
                }
            ]);
        } catch (e) {
            console.log('update schedule error >>>>', e);
        }
    }

    /**
     * Adds admin
     * @param {anchor.web3.PublicKey} admin - public key of new admin
     */
    async addAdmin(admin: anchor.web3.PublicKey) {
        const [config, _bump] = await this.findConfigAddress();
        await this.program.rpc.addAdmin(
            {
                accounts: {
                    config,
                    owner: this.provider.wallet.publicKey,
                    admin,
                }
            }
        );
    }

    /**
     * Removes admin
     * @param {anchor.web3.PublicKey} admin - public key of removing admin
     */
    async removeAdmin(admin: anchor.web3.PublicKey) {
        const [config, _bump] = await this.findConfigAddress();
        await this.program.rpc.removeAdmin(
            {
                accounts: {
                    config,
                    owner: this.provider.wallet.publicKey,
                    admin,
                },
            },
        );
    }

    /**
     * Pause distributor
     * @param {anchor.web3.PublicKey} distributor - public key of pausing distributor
     */
    async pause(distributor: anchor.web3.PublicKey) {
        await this.setPaused(distributor, true);
    }

    /**
     * Unpause distributor
     * @param {anchor.web3.PublicKey} distributor - public key of unpausing distributor
     */
    async unpause(distributor: anchor.web3.PublicKey) {
        await this.setPaused(distributor, false);
    }

    /**
     * Pause or unpause distributor (only for admin role)
     * @param {anchor.web3.PublicKey} distributor - public key of pausing/unpausing distributor
     * @param {boolean} paused - new status for pausing
     */
    async setPaused(distributor: anchor.web3.PublicKey, paused: boolean) {
        const [config, _bump] = await this.findConfigAddress();
        await this.program.rpc.setPaused(
            paused,
            {
                accounts: {
                    distributor,
                    config,
                    adminOrOwner: this.provider.wallet.publicKey
                }
            }
        );
    }

    /**
     * Stop distributor (only for admins)
     * @param {anchor.web3.PublicKey} distributor -- public key of distributor you want to stop
     */
    async stopVesting(distributorAddress: string, total: string, tokenDecimal: number) {
        const distributor = new PublicKey(distributorAddress);
        const [config, _bump] = await this.findConfigAddress();
        await this.program.rpc.stopVesting(
            {
                accounts: {
                    config,
                    distributor,
                    adminOrOwner: this.provider.wallet.publicKey,
                    clock: anchor.web3.SYSVAR_CLOCK_PUBKEY,
                }
            }
        );

        const amountToWithdraw = await this.getAmountAvailableToWithdraw(distributorAddress, total, tokenDecimal);
        console.log('amountToWithdraw >>>', amountToWithdraw);

        const distributorAccount = await this.program.account.merkleDistributor.fetch(distributor);
        const vaultAccount = await this.fetchTokenAccount(distributorAccount.vault);
        const [associatedWallet, ixs] = await this.createAssociated(vaultAccount.mint);
        const targetWallet = associatedWallet;

        await this.withdrawTokens(new anchor.BN(amountToWithdraw), new PublicKey(distributor), targetWallet);
    }

    async withdrawFunds(distributorAddress: string, amount: string) {
        const distributor = new PublicKey(distributorAddress);
        const distributorAccount = await this.program.account.merkleDistributor.fetch(distributor);
        const vaultAccount = await this.fetchTokenAccount(distributorAccount.vault);
        const [associatedWallet, ixs] = await this.createAssociated(vaultAccount.mint);
        const targetWallet = associatedWallet;
        await this.withdrawTokens(new anchor.BN(amount), new PublicKey(distributor), targetWallet);
    }

    /**
     * Withdraws tokens after claim period on target wallet
     * @param {anchor.BN} amount - amount to withdraw
     * @param {anchor.web3.PublicKey} distributor - public key of distributor, on which tokes were claimed
     * @param {anchor.web3.PublicKey} targetWallet - public key of wallet, on which tokens withdraw
     */
    async withdrawTokens(amount: anchor.BN, distributor: anchor.web3.PublicKey, targetWallet: anchor.web3.PublicKey) {
        const distributorAccount = await this.program.account.merkleDistributor.fetch(distributor);
        const [config, _bump] = await this.findConfigAddress();
        const [vaultAuthority, _vaultBump] = await this.findVaultAuthority(distributor);
        await this.program.rpc.withdrawTokens(
            amount,
            {
                accounts: {
                    distributor,
                    config,
                    owner: this.provider.wallet.publicKey,
                    vaultAuthority,
                    vault: distributorAccount.vault,
                    targetWallet,
                    tokenProgram: TOKEN_PROGRAM_ID,
                }
            }
        );
    }

    /**
     * Updates merkle root
     * @param {anchor.web3.PublicKey} distributor - public key of distributor, on which tokes were claimed
     * @param {number[]} merkleRoot - new merkle root to set
     * @param {boolean} unpause (optional) - pause/unpause status
     */
    async updateRoot(distributor: anchor.web3.PublicKey, merkleRoot: number[], unpause?: boolean) {
        const [config, _bump] = await this.findConfigAddress();
        unpause = (unpause === undefined) ? false : unpause;
        await this.program.rpc.updateRoot(
            {
                merkleRoot,
                unpause,
            },
            {
                accounts: {
                    distributor,
                    config,
                    adminOrOwner: this.provider.wallet.publicKey,
                    clock: anchor.web3.SYSVAR_CLOCK_PUBKEY,
                }
            }
        );
    }

    /**
     * Updates shedule
     * @param {anchor.web3.PublicKey} distributor - public key of distributor, on which tokes were claimed
     * @param {any[]} changes - new shedule data
     */
    async updateSchedule(distributor: anchor.web3.PublicKey, changes: any[]) {
        const [config, _bump] = await this.findConfigAddress();
        await this.program.rpc.updateSchedule(
            {
                changes
            },
            {
                accounts: {
                    distributor,
                    config,
                    adminOrOwner: this.provider.wallet.publicKey,
                    clock: anchor.web3.SYSVAR_CLOCK_PUBKEY,
                }
            }
        );
    }

    /**
     * Finds public key of data about user
     * @param {anchor.web3.PublicKey} distributor - public key of distributor, on which tokes were claimed
     * @param {anchor.web3.PublicKey} user - public key of user, which data is finding
     * @returns {Promise<[anchor.web3.PublicKey, number]>} Returns the public key of user details account and the bump
     */
    async findUserDetailsAddress(
        distributor: anchor.web3.PublicKey,
        user: anchor.web3.PublicKey
    ): Promise<[anchor.web3.PublicKey, number]> {
        const distributorAccount = await this.program.account.merkleDistributor.fetch(distributor);
        const [userDetails, bump] = await anchor.web3.PublicKey.findProgramAddress(
            [
                distributor.toBytes(),
                distributorAccount.merkleIndex.toArray('be', 8) as any,
                user.toBytes(),
            ],
            this.program.programId
        );

        return [userDetails, bump];
    }

    async initUserDetailsInstruction(
        distributor: anchor.web3.PublicKey,
        user: anchor.web3.PublicKey
    ): Promise<anchor.web3.TransactionInstruction> {
        const [userDetails, bump] = await this.findUserDetailsAddress(distributor, user);

        const ix = this.program.instruction.initUserDetails(
            bump,
            {
                accounts: {
                    payer: this.provider.wallet.publicKey,
                    user,
                    userDetails,
                    distributor,
                    systemProgram: anchor.web3.SystemProgram.programId,
                }
            }
        );

        return ix;
    }

    /**
     * Initializes user details
     * @param {anchor.web3.PublicKey} distributor - public key of distributor, on which tokes were claimed
     * @param {anchor.web3.PublicKey} user - public key of user, which data is finding
     * @returns {Promise<anchor.web3.PublicKey>} Returns the public key of user details account
     */
    async initUserDetails(
        distributor: anchor.web3.PublicKey,
        user: anchor.web3.PublicKey
    ): Promise<anchor.web3.PublicKey> {
        const [userDetails, bump] = await this.findUserDetailsAddress(distributor, user);
        const userDetailsAccount = await this.getUserDetails(distributor, user);

        if (userDetailsAccount === null) {
            await this.program.rpc.initUserDetails(
                bump,
                {
                    accounts: {
                        payer: this.provider.wallet.publicKey,
                        user,
                        userDetails,
                        distributor,
                        systemProgram: anchor.web3.SystemProgram.programId,
                    }
                }
            );
        }

        return userDetails;
    }

    /**
     * Gets user details data
     * @param {anchor.web3.PublicKey} distributor - public key of distributor, on which tokes were claimed
     * @param {anchor.web3.PublicKey} user - public key of user, which data is finding
     * @returns {Promise<UserDetails | null>} Returns data about user claims (amount, time) or null if err
     */
    async getUserDetails(
        distributor: anchor.web3.PublicKey,
        user: anchor.web3.PublicKey
    ): Promise<UserDetails | null> {
        const [userDetails, _bump] = await this.findUserDetailsAddress(distributor, user);
        return await this.program.account.userDetails.fetchNullable(userDetails);
    }

    async getTotalClaimedPerWallet(
        distributor: string,
        user: string = this.currentAccountValue
    ) {
        const data = await this.getUserDetails(new PublicKey(distributor), new PublicKey(user));
        if (data == null) return '0';
        return data.claimedAmount.toString();
    }

    async fetchDistributorData() {
        const data = await this.program.account.merkleDistributor.all();
        let result = [];
        for (let index = 0; index < data.length; index++) {
            const distributorAccount = await this.program.account.merkleDistributor.fetch(new PublicKey(data[index].publicKey));
            result[index] = distributorAccount.vault;
        }
        return result;
        // get distributor and vault
        // let result = data.map(async (item, index) => {
        //     console.log(`${index} distributor >>> ${new PublicKey(item.publicKey)}`);
        //     const distributorAccount = await this.program.account.merkleDistributor.fetch(new PublicKey(item.publicKey));
        //     console.log(`${index} vault pubkey >>>>> ${new PublicKey(distributorAccount.vault)}`);
        //     return distributorAccount.vault;
        // });
    }

    private getWalletArray(lock: Array<WalletDTO>): Array<WalletDTO> {
        return lock.map(x => ({
            address: x.originalAddress || x.address,
            amount: x.amount
        }));
    }

    async fetchTokenAccount(account: anchor.web3.PublicKey): Promise<spl.AccountInfo> {
        const info = await this.withConnectionRetry(() => this.provider.connection.getAccountInfo(account));
        if (info === null) {
            throw new Error(FAILED_TO_FIND_ACCOUNT);
        }
        if (!info.owner.equals(TOKEN_PROGRAM_ID)) {
            throw new Error(INVALID_ACCOUNT_OWNER);
        }
        if (info.data.length != spl.AccountLayout.span) {
            throw new Error(`Invalid account size`);
        }

        const accountInfo = spl.AccountLayout.decode(info.data);
        accountInfo.mint = new anchor.web3.PublicKey(accountInfo.mint);

        return accountInfo;
    }

    private connectionIndex: number = 0;

    private async withConnectionRetry<T>(func: () => Promise<T>, retryCount: number = 0): Promise<T> {
        try {
            return await func();
        }
        catch (e) {
            if (retryCount < environment.solana.rpcUrls.length) {
                this.connectionIndex++;
                if (this.connectionIndex == environment.solana.rpcUrls.length) {
                    this.connectionIndex = 0;
                }

                this.connection = new Connection(
                    environment.solana.rpcUrls[this.connectionIndex],
                    'confirmed',
                );
                this.provider = this.getProvider(this.currentProvider) as any;
                this.program = this.initProgram();
                this.serumProvider = new serumCmn.Provider(this.provider.connection, this.provider.wallet, opts);
            }
            else {
                throw e;
            }
            return this.withConnectionRetry(func, retryCount + 1);
        }
    }

    async associatedAddress({ mint, owner }: {
        mint: anchor.web3.PublicKey;
        owner: anchor.web3.PublicKey;
    }): Promise<anchor.web3.PublicKey> {
        return (
            await anchor.web3.PublicKey.findProgramAddress(
                [owner.toBytes(), TOKEN_PROGRAM_ID.toBytes(), mint.toBytes()],
                spl.ASSOCIATED_TOKEN_PROGRAM_ID
            )
        )[0];
    }

    async createAssociated(mint: anchor.web3.PublicKey): Promise<[anchor.web3.PublicKey, anchor.web3.TransactionInstruction[]]> {
        console.log('mint >>>', mint);
        const associatedWallet = await this.associatedAddress({
            mint,
            owner: this.provider.wallet.publicKey
        });

        const instructions = [];

        try {
            const targetWalletInfo = await this.fetchTokenAccount(associatedWallet);
            console.log("found associated wallet", targetWalletInfo);
        } catch (err: any) {
            // INVALID_ACCOUNT_OWNER can be possible if the associatedAddress has
            // already been received some lamports (= became system accounts).
            // Assuming program derived addressing is safe, this is the only case
            // for the INVALID_ACCOUNT_OWNER in this code-path
            if (
                err.message === FAILED_TO_FIND_ACCOUNT ||
                err.message === INVALID_ACCOUNT_OWNER
            ) {
                instructions.push(
                    spl.Token.createAssociatedTokenAccountInstruction(
                        spl.ASSOCIATED_TOKEN_PROGRAM_ID,
                        TOKEN_PROGRAM_ID,
                        mint,
                        associatedWallet,
                        this.provider.wallet.publicKey,
                        this.provider.wallet.publicKey,
                    )
                );
            } else {
                throw err;
            }
        }

        return [associatedWallet, instructions];
    }

    async claimToken(distributor: string, wallets: Array<WalletDTO>, originalWallet: string = this.currentAccountValue): Promise<boolean> {
        let result = false;
        try {
            let index = 0;

            const data = wallets.filter((item, i) => {
                if (item.address == this.currentAccountValue) {
                    index = i;
                    return true;
                }
                return false;
            });

            await this.initUserDetails(new PublicKey(distributor), new PublicKey(this.currentAccountValue));

            const { proofs } = this.getMerkleProof(this.getWalletArray(wallets));

            await this.claim(
                new PublicKey(distributor),
                new anchor.BN(data[0].amount),
                new PublicKey(originalWallet),
                proofs[index].proofs
            );
            this.popupService.successClaimPopupSimple();
            result = true;
        } catch (e: any) {
            console.log('claim error ', e);
            this.popupService.failureClaimPopupSimple(e.message);
        }
        return result;
    }

    async getAvailableAmount(distributorAddress: string): Promise<any> {
        const distributor = new PublicKey(distributorAddress);
        const distributorAccount = await this.program.account.merkleDistributor.fetch(distributor);
        let res = await this.connection.getTokenAccountBalance(distributorAccount.vault);
        return res.value.amount;
    }

    async getAmountAvailableToWithdraw(
        distributorAddress: string,
        total: string,
        tokenDecimal: number
    ) {
        const distributor = new PublicKey(distributorAddress);
        const totalAmount = new BigNumber(total);
        const now = Math.trunc(Date.now() / 1000);

        const distributorAccount = await this.program.account.merkleDistributor.fetch(distributor);

        let totalPercentageToWithdraw = new BigNumber(0);

        for (const period of distributorAccount.vesting.schedule as any) {
            let periodStartTs = period.startTs.toNumber();

            if (now >= periodStartTs) {
                continue;
            }

            totalPercentageToWithdraw = totalPercentageToWithdraw.plus(new BigNumber(period.tokenPercentage).dividedBy(SOLANA_TOKEN_PERCENTAGE_DECIMAL));
        }

        return totalPercentageToWithdraw.times(totalAmount).times(Math.pow(10, tokenDecimal)).div(100 * SOLANA_TOKEN_PERCENTAGE_DECIMAL).toString();
    }

    public async hasStopped(lock: LockDTO) {
        //console.log('hasStopped', lock.projectName, this.program.programId.toBase58())
        if (lock.blockchain != 'solana')
            return true;
        const distributor = new PublicKey(lock.contractAddress);
        const distributorAccount = await this.program.account.merkleDistributor.fetch(distributor);
        const now = Math.trunc(Date.now() / 1000);
        for (const period of distributorAccount.vesting.schedule as any) {
            let periodStartTs = period.startTs.toNumber();
            if (now >= periodStartTs) {
                continue;
            }
            if (!period.airdropped) {
                return false;
            }
        }
        return true;
    }

    public async getAmountAvailableToClaim(
        distributorAddress: string,
        userWallet: string,
        total: string,
        tokenDecimal: number,
        lock: LockDTO
    ) {
        const distributor = new PublicKey(distributorAddress);
        const user = new PublicKey(userWallet);
        const totalAmount = new BigNumber(total);

        let lastClaimedAtTs;
        try {
            lastClaimedAtTs = (await this.getUserDetails(distributor, user)).lastClaimedAtTs.toNumber();
        } catch {
            lastClaimedAtTs = 0;
        };

        const distributorAccount = await this.program.account.merkleDistributor.fetch(distributor);
            
        const now = Math.trunc(Date.now() / 1000);
        let totalPercentageToClaim = new BigNumber(0);

        for (const period of distributorAccount.vesting.schedule as any) {
            let periodStartTs = period.startTs.toNumber();
            let periodTimes = period.times.toNumber();

            if (now < periodStartTs) {
                break;
            }

            if (period.airdropped) {
                continue;
            }

            let periodEndTs = periodStartTs + periodTimes * period.intervalSec.toNumber();
            if (periodEndTs <= lastClaimedAtTs) {
                continue;
            }

            let lastClaimedAtTsAlignedByInterval = lastClaimedAtTs - (lastClaimedAtTs % period.intervalSec.toNumber());
            let secondsPassed =
                now - (periodStartTs >= lastClaimedAtTsAlignedByInterval ?
                    periodStartTs : lastClaimedAtTsAlignedByInterval
                );
            let intervalsPassed = secondsPassed / period.intervalSec;
            intervalsPassed = intervalsPassed < periodTimes ? intervalsPassed : periodTimes;

            let percentageForIntervals = new BigNumber(period.tokenPercentage).dividedBy(SOLANA_TOKEN_PERCENTAGE_DECIMAL)
                .dividedBy(periodTimes)
                .times(intervalsPassed);

            totalPercentageToClaim = totalPercentageToClaim.plus(percentageForIntervals);
        }
        return totalPercentageToClaim.times(totalAmount)
            //.times(Math.pow(10, tokenDecimal))
            .div(100)
            .toString();
    }

    /**
     * Claims amount of tokens
     * @param {anchor.web3.PublicKey} distributor - public key of distributor, on which tokes would be claimed
     * @param {anchor.web3.PublicKey} targetWallet - wallet of user, which will withdraw tokens
     * @param {anchor.BN} amount - amount of tokens to claim
     * @param {number[][]} merkleProof - merkle proof
     */
    async claim(
        distributor: anchor.web3.PublicKey,
        amount: anchor.BN,
        originalWallet: anchor.web3.PublicKey,
        merkleProof: number[][],
        targetWallet: anchor.web3.PublicKey = undefined,
    ) {
        const instructions = [];

        const [userDetails, _bump] = await this.findUserDetailsAddress(distributor, this.provider.wallet.publicKey);
        const userDetailsAccount = await this.getUserDetails(distributor, this.provider.wallet.publicKey);
        if (userDetailsAccount === null) {
            instructions.push(
                await this.initUserDetailsInstruction(
                    distributor,
                    this.provider.wallet.publicKey
                ));
        }

        const distributorAccount = await this.program.account.merkleDistributor.fetch(distributor);
        const [vaultAuthority, _vaultBump] = await this.findVaultAuthority(distributor);

        if (targetWallet === undefined) {
            const vaultAccount = await this.fetchTokenAccount(distributorAccount.vault);
            const [associatedWallet, ixs] = await this.createAssociated(vaultAccount.mint);
            targetWallet = associatedWallet;
            if (ixs.length > 0) {
                instructions.push(...ixs);
            }
        }

        const [actualWallet, bump] = await anchor.web3.PublicKey.findProgramAddress(
            [
                distributor.toBytes(),
                originalWallet.toBytes(),
                new TextEncoder().encode("actual-wallet"),
            ],
            this.program.programId
        );

        const actualWalletAccount = await this.program.account.actualWallet.fetchNullable(actualWallet);

        if (actualWalletAccount === null) {
            const ix = this.program.instruction.initActualWallet(
                bump,
                {
                    accounts: {
                        distributor,
                        user: this.provider.wallet.publicKey,
                        actualWallet,
                        systemProgram: anchor.web3.SystemProgram.programId,
                    }
                }
            );
            instructions.push(ix);
        }

        //const [refundRequest, __bump] = await this.findRefundRequestAddress(distributor, originalWallet);

        const claimResult = await this.program.rpc.claim(
            {
                amount,
                merkleProof,
                originalWallet,
            },
            {
                accounts: {
                    distributor,
                    user: this.provider.wallet.publicKey,
                    userDetails,
                    actualWallet,
                    //refundRequest,
                    vaultAuthority,
                    vault: distributorAccount.vault,
                    targetWallet,
                    tokenProgram: TOKEN_PROGRAM_ID,
                    clock: anchor.web3.SYSVAR_CLOCK_PUBKEY,
                },
                instructions,
            },
        );

        console.log('claim tx:', claimResult);
    }

    async getRefundRequests(distributor: string): Promise<any[]> {
        return null;
    }

    public async isRefundRequested(contractAddress: string): Promise<boolean> {
        return false;
    }

    public async isRefunded(contractAddress: string): Promise<boolean> {
        return false;
    }

    public async requestRefund(contractAddress: string): Promise<any> {
    }

    async changeWallet(distributorAddress: string, newWalletAddress: string, originalWalletAddress: string = this.currentAccountValue) {
        const distributor = new PublicKey(distributorAddress);
        const newWallet = new PublicKey(newWalletAddress);
        const originalWallet = new PublicKey(originalWalletAddress);
        console.log('originalWalletAddress >>>', originalWalletAddress);

        await this.initUserDetails(distributor, new PublicKey(this.currentAccountValue));

        const [actualWallet, bump] = await anchor.web3.PublicKey.findProgramAddress(
            [
                distributor.toBytes(),
                originalWallet.toBytes(),
                new TextEncoder().encode("actual-wallet"),
            ],
            this.program.programId
        );

        const actualWalletAccount = await this.program.account.actualWallet.fetchNullable(actualWallet);
        const instructions = [];

        if (actualWalletAccount === null) {
            const ix = this.program.instruction.initActualWallet(
                bump,
                {
                    accounts: {
                        distributor,
                        user: this.provider.wallet.publicKey,
                        actualWallet,
                        systemProgram: anchor.web3.SystemProgram.programId,
                    }
                }
            );
            instructions.push(ix);
        }

        const [userDetails, _] = await this.findUserDetailsAddress(distributor, this.provider.wallet.publicKey);
        const [newUserDetails, userDetailsBump] = await this.findUserDetailsAddress(distributor, newWallet);

        await this.program.rpc.changeWallet(
            userDetailsBump,
            {
                accounts: {
                    distributor,
                    user: this.provider.wallet.publicKey,
                    userDetails,
                    newWallet,
                    newUserDetails,
                    actualWallet,
                    originalWallet,
                    systemProgram: anchor.web3.SystemProgram.programId,
                },
                instructions,
            }
        );
    }

    getMerkleProof(data: any) {
        let totalTokens = 0;
        const elements = data.map((x: any) => {
            const address = new PublicKey(x.address);
            const amount = new anchor.BN(x.amount);
            const leaf = MerkleTree.toLeaf(address, amount);
            totalTokens += x.amount * 1;
            return {
                leaf,
                address,
                amount
            };
        });

        const merkleTree = new MerkleTree(elements.map((x: any) => x.leaf));
        const root = merkleTree.getRoot();

        let proofs = elements.map((x: any) => {
            return {
                proofs: merkleTree.getProof(x.leaf),
                address: x.address,
                amount: x.amount
            };
        });

        const merkleData = {
            root: root,
            totalTokens: totalTokens,
            proofs,
        };

        return merkleData;
    }
}

export class MerkleTree {
    elements: Buffer[];
    layers: any;

    constructor(elements: Buffer[]) {
        // Filter empty strings
        this.elements = elements.filter(el => el);

        // Sort elements
        this.elements.sort(Buffer.compare);
        // Deduplicate elements
        this.elements = this.bufDedup(this.elements);
        if (this.elements.length % 2 === 1) {
            // using default value (array of 32 zeros)
            this.elements.push(this.elements[this.elements.length - 1]);
        }

        // Create layers
        this.layers = this.getLayers(this.elements);
    }

    getLayers(elements: Buffer[]) {
        if (elements.length === 0) {
            return [['']];
        }

        const layers = [];
        layers.push(elements);

        // Get next layer until we reach the root
        while (layers[layers.length - 1].length > 1) {
            layers.push(this.getNextLayer(layers[layers.length - 1]));
        }

        return layers;
    }

    getNextLayer(elements: Buffer[]) {
        return elements.reduce((layer: any, el: any, idx: any, arr: any) => {
            if (idx % 2 === 0) {
                // Hash the current element with its pair element
                layer.push(MerkleTree.combinedHash(el, arr[idx + 1]));
            }

            return layer;
        }, []);
    }

    public static verifyProof(account: any, amount: any, proof: any, root: any) {
        let computedHash = MerkleTree.toLeaf(account, amount);
        for (const item of proof) {
            computedHash = MerkleTree.combinedHash(computedHash, item);
            console.log(computedHash);
        }

        return computedHash.equals(root);
    }

    public static toLeaf(account: anchor.web3.PublicKey, amount: anchor.BN): Buffer {
        const buf = Buffer.concat([
            account.toBuffer(),
            Buffer.from(amount.toArray('be', 8)),
        ]);
        return keccak256(buf);
    }

    static combinedHash(first: any, second: any) {
        if (!first) { return second; }
        if (!second) { return first; }

        return keccak256(MerkleTree.sortAndConcat(first, second));
    }

    getRoot() {
        return this.layers[this.layers.length - 1][0];
    }

    getProof(el: any) {
        let idx = this.bufIndexOf(el, this.elements);

        if (idx === -1) {
            throw new Error('Element does not exist in Merkle tree');
        }

        return this.layers.reduce((proof: any, layer: any) => {
            const pairElement = this.getPairElement(idx, layer);

            if (pairElement) {
                proof.push(pairElement);
            }

            idx = Math.floor(idx / 2);

            return proof;
        }, []);
    }

    getPairElement(idx: any, layer: any) {
        const pairIdx = idx % 2 === 0 ? idx + 1 : idx - 1;

        if (pairIdx < layer.length) {
            return layer[pairIdx];
        } else {
            return null;
        }
    }

    bufIndexOf(el: any, arr: any) {
        let hash;

        // Convert element to 32 byte hash if it is not one already
        if (el.length !== 32 || !Buffer.isBuffer(el)) {
            hash = keccak256(el);
        } else {
            hash = el;
        }

        for (let i = 0; i < arr.length; i++) {
            if (hash.equals(arr[i])) {
                return i;
            }
        }

        return -1;
    }

    bufDedup(elements: any) {
        return elements.filter((el: any, idx: any) => {
            return idx === 0 || !elements[idx - 1].equals(el);
        });
    }

    static sortAndConcat(...args: any) {
        return Buffer.concat([...args].sort(Buffer.compare));
    }
}
